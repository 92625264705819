import * as React from 'react';
import {
    ExceptionMapping,
    ExceptionsEntity,
    IReviewForm,
    ISendBack,
    jobLevelOptions,
    LumpSumRelocation,
    OptionalBenefitMapping,
    PreHireTypeBenefits,
    RelocationType,
} from '../../Models/IReviewForm';
import { LoadingSpinner } from '../../Components/Shared/LoadingSpinner';
import { useServiceStore } from '../../RootStateContext';
import {
    PrimaryButton,
    IStackStyles,
    Stack,
    mergeStyleSets,
    getTheme,
    FontWeights,
    IDropdownOption,
    MessageBar,
    MessageBarType,
    Spinner,
    SpinnerSize,
    TextField,
    IIconProps,
    ITextFieldStyles,
    IStackProps,
    IStackItemStyles,
} from '@fluentui/react';
import { IUserRoles } from '../../Models/IUserRoles';
import { ReduxContext } from '@employee-experience/common';
import { IAppState } from '../../Common/Redux/AppState';
import { HireType } from '../../Models/IReviewForm';
import { PersonalInformation } from '../NewAuthForm/PersonalInformation';
import { EmploymentInformation } from './EmploymentInformation';
import { RelocationInformation } from './RelocationInformation';
import { HireInformation } from './HireInformation';
import { ActionType } from '../../Common/Redux/actionTypes';
import { StackDisclaimer } from '../../Components/Shared/DisclaimerText';
import {
    EmploymentInfo,
    HireInfo,
    ITaxPerJobLevel,
    OptionalBenefitsType,
    PersonalInfo,
    ReloException,
    AttachReloSummary,
} from '../../Models/INewRelocationRequest';
import { RelocationSummaryAttachment } from '../NewAuthForm/RelocationSummaryAttacment';
import * as moment from 'moment';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { SendBackPopUp } from './PopUps/SendBackPopUp';
import { DeleteStatusPopUp } from './PopUps/DeleteStatusPopUp';
import { DeleteWarningPopUp } from './PopUps/DeleteWarningPopUp';

// ---------- Styles ----------
const stackStyles: Partial<IStackStyles> = { root: { width: '100%', marginTop: '7px' } };
const theme = getTheme();
const buttonStyles = {
    marginBottom: '10px',
};

export interface NewAuthFormProps {
    recordID: number;
    recordStatus: string;
    ActionType: string;
    EditRecord: string | null;
}

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: '100em',
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 0 0 0',
        margin: '10px',
        selectors: {
            p: { margin: '14px' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

// --------------------------------------------------
export const ReloForm: React.FC<NewAuthFormProps> = (props) => {
    const location = useLocation();
    let history = useHistory();
    if (location.state == undefined || location.state == null || location.state == '') {
        history.push('/');
    }
    props = JSON.parse(location.state);
    
    const { useSelector, dispatch } = React.useContext(ReduxContext);
    const { reviewFormService, startNewReloRequestService } = useServiceStore();

    const userdata: IUserRoles = useSelector((state: IAppState) => state.root.userReducer.userRoles);

    const [validateOnSubmit, setValidateOnSubmit] = React.useState<boolean>(false);
    const [validateOnReSubmit, setValidateOnReSubmit] = React.useState<boolean>(false);
    const [errorFields, setErrorFields] = React.useState<any[]>([]);
    const [submittedForm, setSubmittedForm] = React.useState<any>();
    const [messageText, setMessageText] = React.useState<string>('');
    const [displayStatus, setDisplayStatus] = React.useState<string | null>(null);
    const [reloTypes, setReloTypes] = React.useState<any[]>([]);
    const [relocationType, setRelocationType] = React.useState<string>('');
    const [parentStatus, set_ParentStatus] = React.useState<string>(props.recordStatus.toUpperCase());

    const [isRedirect, setIsRedirect] = React.useState<boolean>(false);
    const [isLoaded, setIsLoaded] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isSavedAlready, setIsSavedAlready] = React.useState<boolean>(false);
    const [isFormValid, setIsFormValid] = React.useState<boolean>(false);
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = React.useState<boolean>(true);
    const [isEditable, setIsEditable] = React.useState<boolean>(false);
    const [isSubmittable, setIsSubmittable] = React.useState<boolean>(false);

    // Icons for record status
    const [isSubmittedImg, setIsSubmittedImg] = React.useState<boolean>(false);
    const [isSavedImg, setIsSavedImg] = React.useState<boolean>(false);
    const [isReturnForEditsImg, setIsReturnForEditsImg] = React.useState<boolean>(false);
    const [isSendBackImg, setIsSendBackImg] = React.useState<boolean>(false);

    // Pop up visibility
    const [isDeleteWarningPopUpOpen, setIsDeleteWarningPopUpOpen] = React.useState<boolean>(false);
    const [isDeleteConfirmationPopUpOpen, setIsDeleteConfirmationPopUpOpen] = React.useState<boolean>(false);
    const [isDeleteErrorPopUpOpen, setIsDeleteErrorPopUpOpen] = React.useState<boolean>(false);
    const [isSendBackPopUpOpen, setIsSendBackPopUpOpen] = React.useState<boolean>(false);

    // Responses from service store
    const [reloFormDataResponse, setReloFormDataResponse] = React.useState<IReviewForm[]>([]);
    const [exceptionsResponse, setExceptionsResponse] = React.useState<ExceptionsEntity[]>([]);
    const [taxByJobLevelsResponse, setTaxByJobLevelsResponse] = React.useState<ITaxPerJobLevel[]>([]);
    const [jobLevelInfoResponse, setJobLevelInfoResponse] = React.useState<jobLevelOptions[]>([]);

    // Drop down options
    const [relocationPolicyOptions, setRelocationPolicyOptions] = React.useState<IDropdownOption[]>([]);
    const [relocationPolicyTypeOptions, setRelocationPolicyTypeOptions] = React.useState<IDropdownOption[]>([]);
    const [exceptionOptions, setExceptionOptions] = React.useState<IDropdownOption[]>([]);

    // Labels
    const [formSubmitButtonLabel, setFormSubmitButtonLabel] = React.useState<string>('Submit');
    const [hiringManagerLabel, setHiringManagerLabel] = React.useState<string>('Hiring Manager Alias');
    const [hrStaffingNameLabel, setHRStaffingNameLabel] = React.useState<string>('Staffing Manager Alias(GMAT Exception Approver)');

    // Panels, textbox or buttons to show
    const [showPreHireTypePanel, setShowPreHireTypePanel] = React.useState<boolean>(false);
    const [showBenefitsPanel, setShowBenefitsPanel] = React.useState<boolean>(false);
    const [showOptionalException,setShowOptionalException] = React.useState<boolean>(false);
    const [showLumpSumPanel, setShowLumpSumPanel] = React.useState<boolean>(false);

    const [showHrStaffingManagerAliasTextBox, setShowHrStaffingManagerAliasTextBox] = React.useState<boolean>(false);

    const [showButtons, setShowButtons] = React.useState<boolean>(false);
    const [showSaveButton, setShowSaveButton] = React.useState<boolean>(true);

    const [showCancelButton, setShowCancelButton] = React.useState<boolean>(false);
    const [showSendbackButton, setShowSendbackButton] = React.useState<boolean>(false);

    const [showDeleteButton, setShowDeleteButton] = React.useState<boolean>(false);
    const [showUpdateButton, setShowUpdateButton] = React.useState<boolean>(false);

    // Benefits information
    const [preHireTypeBenefits, setPreHireTypeBenefits] = React.useState<PreHireTypeBenefits[]>([]);
    const [selectedRelocationCashAmount, setSelectedRelocationCashAmount] = React.useState<number>(0);
    const [exceptionMapping, setExceptionMapping] = React.useState<ExceptionMapping[]>([]);
    const [selectedException, setSelectedException] = React.useState<string>('');
    const [exceptionListUpdatedFlag, setExceptionListUpdatedFlag] = React.useState<number | null>();
    const [optionalBenefitMapping, setOptionalBenefitMapping] = React.useState<OptionalBenefitMapping[]>([]);

    // Relo form data to save or update
    const [_comments, set_Comments] = React.useState<string>('');
    const [_allOptionalBenefits, set_AllOptionalBenefits] = React.useState<any[]>([]);
    const [_passExceptionList, set_PassExceptionList] = React.useState<ReloException[]>([]);
    const [_formStatus, set_FormStatus] = React.useState<string | null>(null);
    const [_lumpSumRelocation, set_LumpSumRelocation] = React.useState<LumpSumRelocation>({} as LumpSumRelocation);
    const [_selectedPreHireTypeBenefits, set_SelectedPreHireTypeBenefits] = React.useState<any[]>([]);
    const [_preHireTypeBenefitsMapping, set_PreHireTypeBenefitsMapping] = React.useState<any[]>([]);
    const [_relocationCashAmount, set_RelocationCashAmount] = React.useState<number>();
    const [_relocationPolicyID, set_RelocationPolicyID] = React.useState<number>();
    const [_relocationpolicyTypeID, set_RelocationpolicyTypeID] = React.useState<number>();
    const [_taxPctForLumpSum, set_TaxPctForLumpSum] = React.useState<number>(48.5);
    const [_coreAllowance, set_CoreAllowance] = React.useState<any[]>([]);
    const [_coreBenefits, set_CoreBenefits] = React.useState<any[]>([]);
    const [_sendBackComments, set_SendBackComments] = React.useState<boolean>(false);
    const [_fiscalYear, set_FiscalYear] = React.useState<number>();
    
    const [_rushComments, set_rushComments] = React.useState<string>('');
    const [_departureCompanyCode, set_departureCompanyCode] = React.useState<string>('');
    const [_destinationCompanyCode, set_destinationCompanyCode] = React.useState<string>('');
    const [_requisitionID, set_requisitionID] = React.useState<string>('');
    const [_policyReloTypeChanged, set_policyReloTypeChanged] = React.useState<boolean>(false);

    const [_attachmentGUID, set_AttachmentGUID] = React.useState<string>('');
    const [_attachmentGUID0, set_AttachmentGUID0] = React.useState<string>('');
    const [_attachmentGUID1, set_AttachmentGUID1] = React.useState<string>('');
    const [_attachmentGUID2, set_AttachmentGUID2] = React.useState<string>('');
    const [_attachmentGUID3, set_AttachmentGUID3] = React.useState<string>('');
    const [_attachmentGUID4, set_AttachmentGUID4] = React.useState<string>('');

    const emptyAttachReloSummary = {} as AttachReloSummary;
    const [_requiredReloSummary, set_RequiredReloSummary] = React.useState<AttachReloSummary>(emptyAttachReloSummary);
    const [_additionalReloSummary0, set_AdditionalReloSummary0] = React.useState<AttachReloSummary>(emptyAttachReloSummary);
    const [_additionalReloSummary1, set_AdditionalReloSummary1] = React.useState<AttachReloSummary>(emptyAttachReloSummary);
    const [_additionalReloSummary2, set_AdditionalReloSummary2] = React.useState<AttachReloSummary>(emptyAttachReloSummary);
    const [_additionalReloSummary3, set_AdditionalReloSummary3] = React.useState<AttachReloSummary>(emptyAttachReloSummary);
    const [_additionalReloSummary4, set_AdditionalReloSummary4] = React.useState<AttachReloSummary>(emptyAttachReloSummary);

    const [apiSuccess, setApiSuccess] = React.useState<boolean>(false);
    const [apiError, setApiError] = React.useState<boolean>(false);
    const [apiSuccessRemovedFile0, setApiSuccessRemovedFile0] = React.useState<boolean>(false);
    const [apiSuccessRemovedFile1, setApiSuccessRemovedFile1] = React.useState<boolean>(false);
    const [apiSuccessRemovedFile2, setApiSuccessRemovedFile2] = React.useState<boolean>(false);
    const [apiSuccessRemovedFile3, setApiSuccessRemovedFile3] = React.useState<boolean>(false);
    const [apiSuccessRemovedFile4, setApiSuccessRemovedFile4] = React.useState<boolean>(false);

    // References for checking missing required fields
    const employmentInformationRef = React.useRef();
    const personalInformationRef = React.useRef();
    const relocationInformationRef = React.useRef();
    const relocationSummaryAttachmentRef = React.useRef();

    const [hireInfo, setHireInfo] = React.useState<HireInfo>({
        reloID: 0,
        candidateID: '',
        employeeID: '',
        hireTypeID: 0,
        showInternship: false,
        isMACH: false,
        isMBA: false,
        hireTypesOptions: [],
    });

    const emptyPersonalDetails = {
        CostCenter: '',
        CountryNames: [''],
        CurrentEmail: '',
        EmployeeNumber: 0,
        FirstName: '',
        JobLevel: 0,
        LastName: '',
        MiddleName: '',
        PersonalPhone: '',
        ReloID: 0,
        StartDate: '',
        VisaPending: '',
        WorkPhone: '',
        StateNames: [''],
    }

    const emptyCityState = {
        City: '',
        Country: '',
        StateProvince: '',
    }

    const emptyAddress = {
        ...emptyCityState,
        Address: '',
        Apartment: '',
        PostalCode: '',
        ReloID: 0,
    }

    const [personalInfo, setPersonalInfo] = React.useState<PersonalInfo>({
        personalDetails: emptyPersonalDetails,
        departureAddress: emptyAddress,
        workDestinationAddress: emptyCityState,
        homeDestinationAddress: emptyCityState,
        countryOptions: [],
        workStateOptions: [],
        departureAddressStateOptions: [],
        destinationAddressStateOptions: [],
        visaOptions: [],
    });

    const [employmentInfo, setEmploymentInfo] = React.useState<EmploymentInfo>({
        businessGroup: '',
        costCenter: '',
        jobLevel: '',
        jobLevelID: '',
        hRStaffingManagerAlias: '',
        hRRecruitingAssociate: '',
        hiringManagerAlias: '',
        estimatedStartDate: '',
        hRRecruiterAlias: '',
        hrStaffingGroup: '',
        estimatedEndDate: '',
        jobLevelOptions: [],
        businessGroupOptions: [],
        RushComments:'',
        DestinationCompanyCode: '',
        DepartureCompanyCode: '' ,
        RequisitionID: ''
    });

    let EstimatedStartDateLabel: string = 'Estimated Start Date';
    let AttachRelocationSummaryText: string = 'Attach Relocation Summary';

    let exceptionNames: string[] = [];

    React.useEffect(() => {
        if (employmentInfo.jobLevel === "Select One") {
            changeJobLevel();
            set_TaxPctForLumpSum(0);
        } else {
            let selectedJoblevel = employmentInfo.jobLevel === 'Internship' ? 'Interns' : employmentInfo.jobLevel;
            let taxDetails = taxByJobLevelsResponse.filter((item) => item.JobLevelName === selectedJoblevel);
            if (taxDetails.length == 0) {
                set_TaxPctForLumpSum(0);
            }
            else {
                set_TaxPctForLumpSum(Number(taxDetails[0].TaxRate));
                changeJobLevel();
            }
        }

    }, [employmentInfo.jobLevel])

    React.useEffect(() => {
        (async function getReviewFormData() {
            await reviewFormService.getRelocationFormData(props.recordID).then((result) => {
                if (result.length > 0) {
                    setReloFormDataResponse(result);
                } else alert('No Data Found');
            });
        })();
    }, []);

    React.useEffect(() => {
        (async function getReviewFormData() {
            await reviewFormService.getRelocationFormData(props.recordID).then((result) => {
                if (result.length > 0) {
                    setReloFormDataResponse(result);
                } else alert('No Data Found');
            });
        })();
    }, [parentStatus]);

    React.useEffect(() => {
        if (reloFormDataResponse && reloFormDataResponse.length>0) {
            (async function getReloformData() {
                let displayStatus = parentStatus;
                if (reloFormDataResponse !== null && reloFormDataResponse !== undefined) {
                    userdata.Roles.forEach((role) => {
                        let tempRecordStatus = parentStatus;
                        let Action = props.ActionType;
                        if (parentStatus.toUpperCase() == 'NEW') {
                            Action = 'FORM';
                        }

                        if (tempRecordStatus !== null) {
                            if (
                                (role.toUpperCase() == 'STAFFING' || role.toUpperCase() == 'RELOADMIN') &&
                                (Action.toUpperCase() == 'STAFFING' || Action.toUpperCase() == 'FORM')
                            ) {
                                if (
                                    tempRecordStatus.toUpperCase() == 'SAVED' ||
                                    tempRecordStatus.toUpperCase() == 'SENDBACK' ||
                                    tempRecordStatus.toUpperCase() == 'RETURN FOR EDITS' ||
                                    tempRecordStatus.toUpperCase() == 'NEW'
                                ) {
                                    setIsSubmitButtonDisabled(false);
                                    setIsSubmittable(true);
                                }

                                if (tempRecordStatus.toUpperCase() == 'SAVED') {
                                    setIsSavedImg(true);
                                    setShowDeleteButton(true);
                                }

                                if (tempRecordStatus.toUpperCase() === 'NEW') {
                                    setShowDeleteButton(false);
                                }

                                if (tempRecordStatus.toUpperCase() == 'RETURN FOR EDITS') {
                                    set_SendBackComments(true);
                                    setIsSubmittable(true);
                                    setFormSubmitButtonLabel('RESUBMIT');
                                    setShowSaveButton(false);
                                    setDisplayStatus('Return For Edits');
                                    setIsReturnForEditsImg(true);
                                    setShowDeleteButton(true);
                                }

                                if (tempRecordStatus.toUpperCase() == 'SENDBACK') {
                                    set_SendBackComments(true);
                                    setIsSubmittable(true);
                                    setFormSubmitButtonLabel('RESUBMIT');
                                    setShowSaveButton(false);
                                    setDisplayStatus('Send Back');
                                    setIsSendBackImg(true);
                                    setShowDeleteButton(true);
                                }

                                if (
                                    tempRecordStatus.toUpperCase() == 'RESUBMITTED' ||
                                    tempRecordStatus.toUpperCase() == 'SUBMITTED'
                                ) {
                                    setFormSubmitButtonLabel('RESUBMIT');
                                    setIsSubmittedImg(true);
                                    setShowSaveButton(false);
                                    setShowDeleteButton(false);
                                }
                            }

                            if (Action != null) {
                                if (
                                    (role.toUpperCase() == 'GLOMOCO' || role.toUpperCase() == 'RELOADMIN') &&
                                    (Action.toUpperCase() == 'GLOMOCO' || Action.toUpperCase() == 'RELOCAMP')
                                ) {
                                    setIsSubmitButtonDisabled(true);
                                    if (
                                        tempRecordStatus.toUpperCase() == 'SUBMITTED' ||
                                        tempRecordStatus.toUpperCase() == 'RESUBMITTED'
                                    ) {
                                        setShowSendbackButton(true);
                                        setIsSubmitButtonDisabled(false);
                                        setIsSubmittedImg(true);
                                        if (tempRecordStatus.toUpperCase() == 'SUBMITTED')
                                            displayStatus = 'Ready to Initiate';
                                    }
                                    if (tempRecordStatus.toUpperCase() != 'NEW') {
                                        if (props.EditRecord != null) {
                                            if (props.EditRecord == 'editthisrecord') {
                                                setIsEditable(false);
                                                setIsSubmittable(false);
                                                setShowSaveButton(false);
                                                setShowSendbackButton(false);
                                                setShowUpdateButton(true);
                                                setShowCancelButton(true);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    });

                    if (isNotNullOrUndefined(userdata.Alias) && isNotNullOrUndefined(userdata.Roles)) {
                        userdata.Roles.forEach((role) => {
                            if (role.toUpperCase() == 'STAFFING') {
                                if (
                                    reloFormDataResponse[0].RelocationpolicyTypeID == '36' &&
                                    props.recordStatus.toUpperCase() != 'SENDBACK'
                                ) {
                                    setFormSubmitButtonLabel('RESUBMIT');
                                    setShowSaveButton(false);
                                    setIsSubmitButtonDisabled(false);
                                    setShowUpdateButton(false);
                                    setShowCancelButton(false);
                                }
                                if (
                                    reloFormDataResponse[0].RelocationpolicyTypeID == '36' &&
                                    props.recordStatus.toUpperCase() == 'SENDBACK'
                                ) {
                                    if (reloFormDataResponse[0].RelocationpolicyTypeID != '36') {
                                        reloFormDataResponse[0].RelocationpolicyTypeID = '';
                                    }
                                }
                            }
                        });
                    }

                    setDisplayStatus(displayStatus);
                    set_FormStatus(isNotNullOrUndefined(props.recordStatus) ? 'SAVED' : 'New');
                    await getHireInfo(reloFormDataResponse);
                    await getPersonalInfo(reloFormDataResponse);
                    await getEmploymentInfo(reloFormDataResponse);
                    await GetTaxRatesByJobLevel();
                    setShowButtons(true);
                    set_RelocationCashAmount(reloFormDataResponse[0].RelocationCashAmount);
                    set_TaxPctForLumpSum(reloFormDataResponse[0]?.LumpSumRelocation?.TaxRate)
                    if (reloFormDataResponse[0].HireTypeID == 7) {
                        EstimatedStartDateLabel = 'Date of Pre-hire Services';
                        AttachRelocationSummaryText = 'Attach Travel Itinerary';
                    }
                }
            })();
        }
    }, [reloFormDataResponse]);

    React.useEffect(() => 
    {
        if(hireInfo.reloID != 0){
            (async function setReloformData(){
                await getRelocationInfo(reloFormDataResponse);
                if (reloFormDataResponse[0].HireTypeID == 4) {
                    onInternshipTypeChange(reloFormDataResponse[0].InternshipTypeID);
                }
            })();
        }
    },[hireInfo]);

    async function GetTaxRatesByJobLevel() {
        const taxRatesResponse = await startNewReloRequestService.GetTaxRatesByJobLevel();
        dispatch({
            type: ActionType.SET_TAXRATESBYJOBLEVEL,
            data: taxRatesResponse,
        });
        setTaxByJobLevelsResponse(taxRatesResponse);
    }

    async function getHireInfo(data: IReviewForm[]) {
        const hireTypeResponse = await reviewFormService.getHireTypeService();
        let hiretypeoptions: IDropdownOption[] = [];
        hireTypeResponse.forEach((item) => {
            if (item.HireTypeID != 5 && item.HireTypeID != 6)
                hiretypeoptions.push({
                    key: item.HireTypeID,
                    text: item.HireTypeName,
                });
            if (item.HireTypeID == 6)
                hiretypeoptions.push({
                    key: item.HireTypeID,
                    text: 'Executive/ M&A',
                });
        });
        let hireType = hireTypeResponse.filter((row: HireType) => {
            return row.HireTypeID === data[0].HireTypeID;
        });
        const hireInfo = {
            reloID: data[0].ReloID,
            candidateID: data[0].CandidateID != null && data[0].CandidateID != '' ? data[0].CandidateID : '',
            employeeID: data[0].EmployeeID != null && data[0].EmployeeID != 0 ? data[0].EmployeeID.toString() : '',
            showInternship: hireType[0].HireTypeName === 'Intern' ? true : false,
            showMachAndMba: data[0].HireTypeID === 3 ? true : false,
            isMACH: data[0].IsMach === 1 ? true : false,
            isMBA: data[0].IsMba === 1 ? true : false,
            hireTypesOptions: hiretypeoptions,
            hireTypeID: data[0].HireTypeID,
        };
        setHireInfo(hireInfo);
    }

    async function getPersonalInfo(data: IReviewForm[]) {
        const updatedPersonalInfo = {
            ...personalInfo,
            personalDetails:
                isNotNullOrUndefined(data[0].PersonalDetails)
                    ? data[0].PersonalDetails
                    : emptyPersonalDetails,
            workDestinationAddress: {
                City: data[0].WorkDestinationCity,
                Country: data[0].WorkDestinationCountry,
                StateProvince: data[0].WorkDestinationStateProvince,
            },
            homeDestinationAddress:
                isNotNullOrUndefined(data[0].DestinationAddress)
                    ? data[0].DestinationAddress
                    : emptyAddress,
            departureAddress:
                isNotNullOrUndefined(data[0].DepartureAddress)
                    ? {
                        ...data[0].DepartureAddress,
                        PostalCode: isNotNullOrUndefined(data[0].DepartureAddress.PostalCode)
                            ? (data[0].DepartureAddress.PostalCode).toString()
                            : '',
                        ReloID: isNotNullOrUndefined(data[0].DepartureAddress.ReloID)
                            ? (data[0].DepartureAddress.ReloID)
                            : 0
                    }
                    : emptyAddress,
            visaOptions: [
                { key: 'Yes', text: 'Yes' },
                { key: 'No', text: 'No' },
            ],
        };
        setPersonalInfo(updatedPersonalInfo);
    }

    function isValidString(value: string | null | undefined) {
        if (isNotNullOrUndefined(value) && value !== '') {
            return true;
        }
        return false;
    }

    async function getEmploymentInfo(data: IReviewForm[]) {
        const jobLevelsOptionResponse = await reviewFormService.getJobLevelInformation();
        jobLevelsOptionResponse.splice(0, 0, { ID: '0', JobLevelName: 'Select One', IsRemoved: false });
        setJobLevelInfoResponse(jobLevelsOptionResponse);
        let jobLevel = jobLevelsOptionResponse;
        const employmentInfo = {
            businessGroup: data[0].BusinessGroup,
            costCenter: data[0].CostCenter,
            jobLevel:
                data[0].JobLevelID == null
                    ? jobLevel[0].JobLevelName
                    : jobLevelsOptionResponse.find((item) => {
                        return item.ID == data[0].JobLevelID;
                    })?.JobLevelName!,
            jobLevelID: isValidString(data[0].JobLevelID)
                ? data[0].JobLevelID
                : jobLevel[0].ID,
            hRStaffingManagerAlias: isValidString(data[0].HRStaffingManagerAlias)
                ? data[0].HRStaffingManagerAlias
                : data[0].HRRecruitingAssociate, // E-Form Submitters Manager Alias
            hRRecruitingAssociate: isValidString(data[0].HRRecruitingAssociate)
                ? data[0].HRRecruitingAssociate
                : userdata.Alias, // F-Form Submitter Alias
            hiringManagerAlias: data[0].HiringManagerAlias, // Hiring Manager Alias
            estimatedStartDate: isNotNullOrUndefined(data[0].EstimatedStartDate)
                ? data[0].EstimatedStartDate
                : '',
            estimatedEndDate: isNotNullOrUndefined(data[0].EstimatedEndDate)
                ? data[0].EstimatedEndDate
                : '',
            hrStaffingGroup: data[0].HRStaffinggroup,
            hRRecruiterAlias: data[0].HRRecruiterAlias,
            jobLevelOptions: [],
            businessGroupOptions: [],
            RushComments: data[0].RushComments,
            DestinationCompanyCode: data[0].DestinationCompanyCode,
            DepartureCompanyCode: data[0].DepartureCompanyCode,
            RequisitionID: data[0].RequisitionID,
        };

        setEmploymentInfo(employmentInfo);
    }

    async function getRelocationInfo(data: IReviewForm[]) {
        const relocationPolicyResponse = await reviewFormService.getrelocationPolicy(data[0].HireTypeID);
        const newRelocationPolicyTypeOptions = relocationPolicyResponse.map(item => ({ key: item.RelocationPolicyID, text: item.RelocationPolicyName }));

        set_RequiredReloSummary(
            data[0].AttachReloSummary != null
                ? data[0].AttachReloSummary : ({} as AttachReloSummary)
        );
        set_RelocationPolicyID(parseInt(data[0].RelocationPolicyID));
        set_RelocationpolicyTypeID(parseInt(data[0].RelocationpolicyTypeID));
        setExceptionMapping(data[0].ExceptionMapping);
        setExceptionListUpdatedFlag(0);
        set_Comments(data[0].Comments!);
        set_rushComments(data[0].RushComments!);
        set_departureCompanyCode(data[0].DepartureCompanyCode!);
        set_destinationCompanyCode(data[0].DestinationCompanyCode!);
        set_requisitionID(data[0].RequisitionID!);
        set_LumpSumRelocation(reloFormDataResponse[0].LumpSumRelocation);
        set_FiscalYear(
            data[0].FiscalYear !== null || data[0].FiscalYear !== undefined || data[0].FiscalYear !== 0
                ? data[0].FiscalYear
                : undefined
        );
        setOptionalBenefitMapping(
            data[0].OptionalBenefitMapping !== null || data[0].OptionalBenefitMapping !== undefined
                ? data[0].OptionalBenefitMapping
                : ([] as OptionalBenefitMapping[])
        );
        set_AdditionalReloSummary0(
            data[0].AdditonalReloSummary0 != null
                ? data[0].AdditonalReloSummary0 : ({} as AttachReloSummary)
        );
        set_AdditionalReloSummary1(
            data[0].AdditonalReloSummary1 != null
                ? data[0].AdditonalReloSummary1 : ({} as AttachReloSummary)
        );
        set_AdditionalReloSummary2(
            data[0].AdditonalReloSummary2 != null
                ? data[0].AdditonalReloSummary2 : ({} as AttachReloSummary)
        );
        set_AdditionalReloSummary3(
            data[0].AdditonalReloSummary3 != null
                ? data[0].AdditonalReloSummary3 : ({} as AttachReloSummary)
        );
        set_AdditionalReloSummary4(
            data[0].AdditonalReloSummary4 != null
                ? data[0].AdditonalReloSummary4 : ({} as AttachReloSummary)
        );
        set_AttachmentGUID(isNotNullOrUndefined(data[0].AttachmentGUID) ? data[0].AttachmentGUID : '');
        set_AttachmentGUID0(isNotNullOrUndefined(data[0].AttachmentGUID0) ? data[0].AttachmentGUID0 : '');
        set_AttachmentGUID1(isNotNullOrUndefined(data[0].AttachmentGUID1) ? data[0].AttachmentGUID1 : '');
        set_AttachmentGUID2(isNotNullOrUndefined(data[0].AttachmentGUID2) ? data[0].AttachmentGUID2 : '');
        set_AttachmentGUID3(isNotNullOrUndefined(data[0].AttachmentGUID3) ? data[0].AttachmentGUID3 : '');
        set_AttachmentGUID4(isNotNullOrUndefined(data[0].AttachmentGUID4) ? data[0].AttachmentGUID4 : '');
        set_PreHireTypeBenefitsMapping(
            isNotNullOrUndefined(data[0].PreHireTypeBenefitsMapping)
                ? data[0].PreHireTypeBenefitsMapping
                : []
        );
        setRelocationPolicyOptions(newRelocationPolicyTypeOptions);
        setSelectedRelocationCashAmount(data[0].RelocationCashAmount);
        if (isValidString(reloFormDataResponse[0].RelocationPolicyID)) {
            let obj: IDropdownOption = {} as IDropdownOption;
            relocationPolicyResponse.forEach((item) => {
                if (item.RelocationPolicyID === reloFormDataResponse[0].RelocationPolicyID) {
                    (obj.key = item.RelocationPolicyID), (obj.text = item.RelocationPolicyName);
                }
            });
            LoadRelocationType(obj);
        } else {
            LoadRelocationType({} as IDropdownOption);
        }
    }

    const toggleDeleteWarningPopUp = (flag: boolean) => {
        setIsDeleteWarningPopUpOpen(flag);
    };
    const toggleDeleteStatusPopUp = (status?: string) => {
        if (status === 'DeleteSuccess') {
            setIsDeleteWarningPopUpOpen(false);
            setIsDeleteConfirmationPopUpOpen(true);
        } else if (status === 'DeleteError') {
            setIsDeleteWarningPopUpOpen(false);
            setIsDeleteErrorPopUpOpen(true);
        } else if (isDeleteConfirmationPopUpOpen === true) {
            setIsDeleteConfirmationPopUpOpen(false);
            setIsRedirect(true);
        } else if (isDeleteErrorPopUpOpen === true) {
            setIsDeleteErrorPopUpOpen(false);
        }
    };

    const onSendBackClick = async () => {
        setIsSendBackPopUpOpen(true);
    };

    const dismissSendbackPopUp = () => {
        setIsSendBackPopUpOpen(false);
    };

    const updateisSendBack = () => {
        set_ParentStatus('SENDBACK');
    };

    const getSelectedOptionalBenefits = () => {
        let selectedOptionalBenefits: OptionalBenefitMapping[] = [];
        if (_allOptionalBenefits.length > 0 && isNotNullOrUndefined(_allOptionalBenefits)) {
            _allOptionalBenefits.forEach((item) => {
                if (!item.disableStatus) {
                    let selectedBenefit: OptionalBenefitMapping = item;
                    selectedBenefit.Unit =
                        (item.UnitType !== 'FreeField' && (item.Unit === '' || isNaN(Number(item.Unit))))
                            ? '1'
                            : item.Unit;
                    selectedBenefit.Amount = item.UnitType === 'FreeField' ? item.Amount : null;
                    selectedOptionalBenefits.push(selectedBenefit);
                }
            });
        }
        return selectedOptionalBenefits;
    }

    const getSelectedExceptions = () => {
        let selectedGridExceptions: ExceptionMapping[] = [];
        if (_passExceptionList.length > 0 && isNotNullOrUndefined(_passExceptionList)) {
            _passExceptionList.forEach((item) => {
                selectedGridExceptions.push(item);
            });
        }
        return selectedGridExceptions;
    }

    function getHireInfoToSaveOrSubmit() {
        return {
            ReloID: hireInfo.reloID ? hireInfo.reloID : 0,
            CandidateID: hireInfo.candidateID ? hireInfo.candidateID : null,
            EmployeeID: isValidString(hireInfo.employeeID) ? hireInfo.employeeID : '0',
            IsMach: hireInfo.isMACH ? 1 : 0,
            IsMba: hireInfo.isMBA ? 1 : 0,
            HireTypeID: hireInfo.hireTypeID
        }
    }

    const onSubmitClick = async (e: any) => {
        e.preventDefault();
        let isSubmit = true;
        let formData: any = getReloFormToSaveOrSubmit(isSubmit);
        setSubmittedForm(formData);
    };

    React.useEffect(() => {
        if (submittedForm !== undefined) {
            if (!validateOnSubmit) {
                setValidateOnSubmit(true);
                setValidateOnReSubmit(false);
            } else {
                setValidateOnSubmit(false);
                setValidateOnReSubmit(true);
            }
        }
    }, [submittedForm]);

    React.useEffect(() => {
        if (validateOnSubmit || validateOnReSubmit) {
            if (submittedForm == undefined || submittedForm == null) {
                setApiError(true);
                setMessageText('Please Enter a Valid Data Before Submitting The Form');
            } else {
                if (submittedForm?.HireTypeID != 6 && submittedForm?.HireTypeID != 7) {
                    if (!isValidString(submittedForm?.CandidateID) ||
                        submittedForm?.CandidateID == '0'
                    ) {
                        if (!isValidString(submittedForm?.EmployeeID.toString()) ||
                            submittedForm?.EmployeeID == 0
                        ) {
                            setApiError(true);
                            setMessageText(
                                'Please Enter a Valid Candidate ID or Employee ID or HireType Before Submitting The Form'
                            );
                        } else {
                            if (isFormValid) {
                                mainSubmit(submittedForm);
                            }
                        }
                    } else {
                        if (isFormValid) {
                            mainSubmit(submittedForm);
                        }
                    }
                } else {
                    if (isFormValid) {
                        mainSubmit(submittedForm);
                    }
                }
            }
        }
    }, [validateOnSubmit, validateOnReSubmit, isFormValid]);

    React.useEffect(() => {
        if (validateOnSubmit || validateOnReSubmit) {
            if (errorFields.length === 0) {
                setIsFormValid(true);
            }else{
                if(validateOnSubmit){
                    setValidateOnSubmit(false);
                }else{
                    setValidateOnReSubmit(false);
                }
            }
        }
    }, [errorFields]);

    React.useEffect(() => {
        (async function onrelotypeChange() {
            if (reloTypes.length > 0 && isValidString(reloFormDataResponse[0].RelocationpolicyTypeID)
            ) {
                await changeReloType(parseInt(reloFormDataResponse[0].RelocationpolicyTypeID));
                setIsLoaded(true);
            }
            if(Object.keys(reloFormDataResponse[0]).length !== 0) {
                setIsLoaded(true);
            }
        })();
    }, [reloTypes]);

    const mainSubmit = async (data: IReviewForm) => {
        const { errors: employmentInformationErrors } = employmentInformationRef.current.validateForm();
        const { errors: hireInformationValues } = personalInformationRef.current.validateForm()
        const { errors: relocationInformationValues } = relocationInformationRef.current.validateForm();
        const { errors: relocationSummaryAttachmentValues } = relocationSummaryAttachmentRef.current.validateForm();
        if (errorFields.length === 0 && employmentInformationErrors?.length === 0 &&
            hireInformationValues?.length === 0 && relocationInformationValues?.length === 0 &&
            relocationSummaryAttachmentValues?.length === 0) {
            let savedReloResponse = await saveRelocationFormData(true, data);
            let submitObj: ISendBack = {} as ISendBack;
            submitObj.status = 'Submitted';
            if(data.RelocationPolicyID=='7')
            {
                submitObj.status = 'BenivoSubmission'
            }
            else if (parentStatus == 'SENDBACK' || parentStatus == 'RESUBMITTED' || parentStatus == "RETURN FOR EDITS") {
                submitObj.status = 'Resubmitted';
            }
            submitObj.reloID = savedReloResponse.ReloID;
            submitObj.alias = userdata.Alias;
            submitObj.comments = _comments ? _comments : '';
            submitObj.personalDetails = savedReloResponse.PersonalDetails;
            submitObj.estimatedStartDate = new Date(savedReloResponse.EstimatedStartDate);

            setIsLoading(true);
            await startNewReloRequestService
                .Submit(submitObj)
                .then((res) => {
                    setMessageText('Success! This record has been successfully submitted.');
                    setIsRedirect(true);
                })
                .catch(() => {
                    setMessageText('Error! This record was not submitted, please try again.');
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const erroredFields = (errorFields: any) => {
        setErrorFields((preState) => {
            let mySet = new Set([...preState, ...errorFields]);
            return Array.from(mySet);
        });
    };

    const clearedFields = (clearField: any) => {
        let filteredFields: any[] = [];
        if (Array.isArray(clearField)) {
            filteredFields = errorFields;
            clearField.map((element) => {
                filteredFields = filteredFields.filter((item) => item !== element);
            });
        } else {
            filteredFields = errorFields.filter((item) => item !== clearField);
        }
        setErrorFields(filteredFields);
    };

    const handleErrorMessageBar = () => {
        setApiError(false);
        setMessageText('');
    };

    const handleSuccessMessageBar = () => {
        setApiSuccess(false);
        setMessageText('');
    };

    const iconPropsWarnings: IIconProps = {
        iconName: 'WarningSolid',
        styles: {
            root: { color: '#EC9F1D', fontSize: '22px', paddingLeft: '3px' },
        },
    };

    const iconPropsError: IIconProps = {
        iconName: 'StatusErrorFull',
        styles: {
            root: { color: '#DF0008', fill: 'Black', fontSize: '22px', paddingLeft: '3px' },
        },
    };

    const iconPropsSubmitted: IIconProps = {
        iconName: 'SkypeCircleCheck',
        styles: {
            root: { color: '#7BA811', fill: 'Black', fontSize: '22px', paddingLeft: '0px' },
        },
    };

    const sendBackCommentsStyles: Partial<ITextFieldStyles> = {
        field: {
            color: '#034778',
            fontSize: '20px',
            fontFamily: 'Segoe UI',
            paddingRight: '0px',
            flexDirection: 'row-reverse',
            backgroundColor: '#D9EDF7',
        },
    };

    const textFieldStyles: Partial<ITextFieldStyles> = {
        field: {
            color: 'Black',
            fontSize: '20px',
            fontFamily: 'Segoe UI',
            paddingRight: '0px',
            flexDirection: 'row-reverse',
        },
    };

    const oneColumnProps: Partial<IStackProps> = {
        tokens: { childrenGap: 15 },
        styles: { root: { width: '100%' } },
    };

    const accordionHeader: IStackItemStyles = {
        root: {
            padding: 5,
            cursor: 'pointer',
            fontSize: '20px',
        },
    };

    return (
        <>
            {!isLoaded ? (
                <LoadingSpinner />
            ) : (
                <>
                    {/* Pop Ups */}
                    {isDeleteWarningPopUpOpen && (
                        <DeleteWarningPopUp
                            isModalOpen={isDeleteWarningPopUpOpen}
                            toggleDeleteWarningPopUp={toggleDeleteWarningPopUp}
                            toggleDeleteStatusPopUp={toggleDeleteStatusPopUp}
                            reloId={hireInfo.reloID}
                        />
                    )}
                    {isDeleteConfirmationPopUpOpen && (
                        <DeleteStatusPopUp
                            isModalOpen={isDeleteConfirmationPopUpOpen}
                            toggleDeleteStatusPopUp={toggleDeleteStatusPopUp}
                            message={'This record was successfully deleted.'}
                        />
                    )}
                    {isDeleteErrorPopUpOpen && (
                        <DeleteStatusPopUp
                            isModalOpen={isDeleteErrorPopUpOpen}
                            toggleDeleteStatusPopUp={toggleDeleteStatusPopUp}
                            message={"The record wasn't successfully deleted."}
                        />
                    )}
                    {isSendBackPopUpOpen && (
                        <SendBackPopUp
                            dismiss={dismissSendbackPopUp}
                            formData={reloFormDataResponse[0]}
                            LoggedInAlias={userdata.Alias}
                            updateisSendBack={updateisSendBack}
                        />
                    )}

                    {isLoading && (
                        <Stack>
                            <div>
                                <Spinner
                                    label="Loading, please wait..."
                                    size={SpinnerSize.large}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        height: '100%',
                                        width: '100%',
                                        zIndex: 9999999,
                                        backgroundColor: 'rgb(255, 255, 255, 255)',
                                    }}
                                />
                            </div>
                        </Stack>
                    )}

                    {/* Status */}
                    <h1><Stack
                        horizontal={true}
                        className="statusSection"
                        style={{ borderTop: '3px solid #dcdcdc' }}
                        horizontalAlign="end"
                    >
                        <Stack.Item>
                            {isSubmittedImg &&
                                (displayStatus?.toLowerCase() == 'resubmitted' ||
                                    displayStatus?.toLowerCase() == 'authorized') && (
                                    <TextField
                                        iconProps={iconPropsWarnings}
                                        ariaLabel="Status"
                                        value={displayStatus}
                                        styles={textFieldStyles}
                                        borderless
                                        readOnly={true}
                                    ></TextField>
                                )}

                            {isSubmittedImg &&
                                displayStatus?.toLowerCase() !== 'resubmitted' &&
                                displayStatus?.toLowerCase() !== 'authorized' && (
                                    <TextField
                                        iconProps={iconPropsSubmitted}
                                        ariaLabel="Status"
                                        value={displayStatus!}
                                        styles={textFieldStyles}
                                        borderless
                                        readOnly={true}
                                    ></TextField>
                                )}
                            {isSavedImg && (
                                <TextField
                                    iconProps={iconPropsWarnings}
                                    ariaLabel="Status"
                                    value={displayStatus!}
                                    styles={textFieldStyles}
                                    borderless
                                    readOnly={true}
                                ></TextField>
                            )}

                            {isReturnForEditsImg && (
                                <TextField
                                    iconProps={iconPropsError}
                                    ariaLabel="Status"
                                    value={displayStatus!}
                                    styles={textFieldStyles}
                                    borderless
                                    readOnly={true}
                                ></TextField>
                            )}

                            {isSendBackImg && (
                                <TextField
                                    iconProps={iconPropsError}
                                    ariaLabel="Status"
                                    value={displayStatus!}
                                    styles={textFieldStyles}
                                    borderless
                                    readOnly={true}
                                ></TextField>
                            )}
                        </Stack.Item>
                    </Stack></h1>

                    {apiSuccess && (
                        <MessageBar
                            messageBarType={MessageBarType.success}
                            isMultiline={false}
                            onDismiss={handleSuccessMessageBar}
                            dismissButtonAriaLabel="Close"
                        >
                            {messageText}
                        </MessageBar>
                    )}
                    {apiSuccessRemovedFile0 && (
                        <MessageBar
                            messageBarType={MessageBarType.success}
                            isMultiline={false}
                            dismissButtonAriaLabel="Close"
                            role={'alert'}
                        >
                            {messageText}
                        </MessageBar>
                    )}

                    {apiSuccessRemovedFile1 && (
                        <MessageBar
                            messageBarType={MessageBarType.success}
                            isMultiline={false}
                            dismissButtonAriaLabel="Close"
                            role={'alert'}
                        >
                            {messageText}
                        </MessageBar>
                    )}
                    {apiSuccessRemovedFile2 && (
                        <MessageBar
                            messageBarType={MessageBarType.success}
                            isMultiline={false}
                            dismissButtonAriaLabel="Close"
                            role={'alert'}
                        >
                            {messageText}
                        </MessageBar>
                    )}
                    {apiSuccessRemovedFile3 && (
                        <MessageBar
                            messageBarType={MessageBarType.success}
                            isMultiline={false}
                            dismissButtonAriaLabel="Close"
                            role={'alert'}
                        >
                            {messageText}
                        </MessageBar>
                    )}
                    {apiSuccessRemovedFile4 && (
                        <MessageBar
                            messageBarType={MessageBarType.success}
                            isMultiline={false}
                            dismissButtonAriaLabel="Close"
                            role={'alert'}
                        >
                            {messageText}
                        </MessageBar>
                    )}
                    {apiError && (
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={false}
                            onDismiss={handleErrorMessageBar}
                            dismissButtonAriaLabel="Close"
                        >
                            {messageText}
                        </MessageBar>
                    )}

                    {_sendBackComments && (
                        <Stack {...oneColumnProps} styles={accordionHeader}>
                            <TextField
                                label="Comments"
                                multiline
                                rows={3}
                                readOnly={true}
                                value={reloFormDataResponse[0].SendBackComments}
                                styles={sendBackCommentsStyles}
                                draggable={false}
                            ></TextField>
                        </Stack>
                    )}

                    <Stack className={contentStyles.body}>
                        <HireInformation
                            hireInfo={hireInfo}
                            internshipTypeID={reloFormDataResponse[0].InternshipTypeID}
                            updateHireInfo={updateHireInfo}
                            onInternshipTypeChange={onInternshipTypeChange}
                        />
                        <PersonalInformation
                            personalInfo={personalInfo}
                            updatePersonalDetails={updatePersonalDetails}
                            updateDepartureAddress={updateDepartureAddress}
                            updateDestinationAddress={updateDestinationAddress}
                            homeDestinationAddressDetails={homeDestinationAddressDetails}
                            bulkHomeDestinationAddressUpdate={bulkHomeDestinationAddressUpdate}
                            validateOnSubmit={validateOnSubmit}
                            validateOnReSubmit={validateOnReSubmit}
                            erroredFields={erroredFields}
                            clearedFields={clearedFields}
                            errorFieldsState={errorFields}
                            ref={personalInformationRef}
                        />
                        <EmploymentInformation
                            employmentInfo={employmentInfo}
                            jobLevelData={jobLevelInfoResponse}
                            updateEmploymentInfo={updateEmploymentInfo}
                            onJobLevelChange={changeJobLevel}
                            updateJobLevelInfo={updateJobLevelInfo}
                            showHrStaffingManagerAliastxtBox={showHrStaffingManagerAliasTextBox}
                            HrStaffingNameLabel={hrStaffingNameLabel}
                            HrHiringManagerLabel={hiringManagerLabel}
                            InternshipID={reloFormDataResponse[0].InternshipTypeID}
                            HireTypeID={reloFormDataResponse[0].HireTypeID}
                            validateOnSubmit={validateOnSubmit}
                            validateOnReSubmit={validateOnReSubmit}
                            erroredFields={erroredFields}
                            clearedFields={clearedFields}
                            EstimatedStartDateLabelText={EstimatedStartDateLabel}
                            RushComments={_rushComments}
                            relocationPolicyID={_relocationPolicyID}
                            relocationpolicyTypeID={_relocationpolicyTypeID}
                            relocationType={relocationType}
                            PolicyReloTypeChanged={_policyReloTypeChanged}
                            ref={employmentInformationRef}
                        />
                        <RelocationInformation
                            taxPctForLumpSum={_taxPctForLumpSum}
                            hireTypeOption={reloFormDataResponse[0].HireTypeID}
                            changeReloType={changeReloType}
                            handleExceptionUnitValueUpdate={handleExceptionUnitValueUpdate}
                            handleRemoveException={handleRemoveException}
                            handleAddException={handleAddException}
                            onRelocationPolicyChange={LoadRelocationType}
                            showBenefitsPanel={showBenefitsPanel}
                            showOptionalException={showOptionalException}
                            relocationPolicyID={_relocationPolicyID}
                            relocationpolicyTypeID={_relocationpolicyTypeID}
                            relocationType={relocationType}
                            comments={_comments}
                            relocationPolicyOptions={relocationPolicyOptions}
                            relocationPolicyTypeOptions={relocationPolicyTypeOptions}
                            exceptionOptions={exceptionOptions}
                            passExceptionList={_passExceptionList}
                            exceptionListUpdatedFlag={exceptionListUpdatedFlag}
                            showLumpSumPanel={showLumpSumPanel}
                            showPreHireTypePanel={showPreHireTypePanel}
                            allOptionalBenefits={_allOptionalBenefits}
                            preHireTypeBenefits={preHireTypeBenefits}
                            coreBenefits={_coreBenefits}
                            coreAllowance={_coreAllowance}
                            updateRelocationComments={updateRelocationComments}
                            updateSelectedException={updateSelectedException}
                            handleOptionalBenefitUnitUpdate={handleOptionalBenefitUnitUpdate}
                            updateLumSumNetCash={updateLumSumNetCash}
                            updateRelocationCashAmt={updateRelocationCashAmt}
                            updateLumSumAccuralCost={updateLumSumAccuralCost}
                            updatePreHireSelectedBenefits={updatePreHireSelectedBenefits}
                            validateOnSubmit={validateOnSubmit}
                            validateOnReSubmit={validateOnReSubmit}
                            erroredFields={erroredFields}
                            clearedFields={clearedFields}
                            exceptionsResponse={exceptionsResponse}
                            relocationCashAmount={_relocationCashAmount}
                            lumpSumRelocation={_lumpSumRelocation}
                            taxpactforlumpsum={_taxPctForLumpSum}
                            ref={relocationInformationRef}
                        />
                        <RelocationSummaryAttachment
                            uploadaddfile={uploadaddfile}
                            uploadfile={uploadfile}
                            RemoveSelectedReloFile={RemoveSelectedReloFile}
                            RemoveAddSelectedReloFile={RemoveAddSelectedReloFile}
                            updateAdditionalDescriptionValue={updateAdditionalDescriptionValue}
                            validateOnSubmit={validateOnSubmit}
                            validateOnReSubmit={validateOnReSubmit}
                            erroredFields={erroredFields}
                            clearedFields={clearedFields}
                            reloFile={_requiredReloSummary}
                            hireTypeID={reloFormDataResponse[0].HireTypeID}
                            addtionalReloFile0={_additionalReloSummary0}
                            addtionalReloFile1={_additionalReloSummary1}
                            addtionalReloFile2={_additionalReloSummary2}
                            addtionalReloFile3={_additionalReloSummary3}
                            addtionalReloFile4={_additionalReloSummary4}
                            GUID0={_attachmentGUID0}
                            GUID1={_attachmentGUID1}
                            GUID2={_attachmentGUID2}
                            GUID3={_attachmentGUID3}
                            GUID4={_attachmentGUID4}
                            reloID={reloFormDataResponse[0].ReloID}
                            AttachRelocationSummaryText={AttachRelocationSummaryText}
                            hidemessageBox={hidemessageBox}
                            ref={relocationSummaryAttachmentRef}
                        />

                        {/* Buttons */}
                        {showButtons && (
                            <Stack
                                className="SubmitButtons"
                                horizontal
                                horizontalAlign="end"
                                tokens={{ childrenGap: 20 }}
                                styles={stackStyles}
                            >
                                {showSaveButton && (
                                    <PrimaryButton
                                        text="Save"
                                        onClick={() => {
                                            OnSaveClick(false);
                                        }}
                                        allowDisabledFocus
                                        disabled={false}
                                        style={buttonStyles}
                                    />
                                )}
                                {(isEditable || isSubmittable) && (
                                    <PrimaryButton
                                        text={formSubmitButtonLabel}
                                        onClick={(e) => onSubmitClick(e)}
                                        allowDisabledFocus
                                        disabled={isSubmitButtonDisabled}
                                        style={buttonStyles}
                                    />
                                )}
                                {showDeleteButton && (
                                    <PrimaryButton
                                        text="Delete e-form"
                                        onClick={() => toggleDeleteWarningPopUp(true)}
                                        allowDisabledFocus
                                        disabled={isSubmitButtonDisabled}
                                        style={buttonStyles}
                                    />
                                )}
                                {showSendbackButton && (
                                    <PrimaryButton
                                        text="Send Back"
                                        onClick={async () => {
                                            await onSendBackClick();
                                        }}
                                        allowDisabledFocus
                                        disabled={false}
                                        style={buttonStyles}
                                    />
                                )}
                                {showUpdateButton && (
                                    <PrimaryButton
                                        text="Update"
                                        onClick={() => {
                                            OnUpdateClick();
                                        }}
                                        allowDisabledFocus
                                        disabled={false}
                                        style={buttonStyles}
                                    />
                                )}

                                {showCancelButton && (
                                    <PrimaryButton
                                        text="Cancel"
                                        onClick={async () => {
                                            let obj = JSON.stringify({
                                                recordID: props.recordID,
                                                recordStatus: props.recordStatus,
                                                ActionType: 'GLOMOCO',
                                            });
                                            history.push('/ReviewForm', obj);
                                        }}
                                        allowDisabledFocus
                                        disabled={false}
                                        style={buttonStyles}
                                    />
                                )}
                            </Stack>
                        )}
                        {StackDisclaimer()}
                    </Stack>
                </>
            )}
            {isRedirect && <Redirect to="/MyReloQueue" />}
        </>
    );

    function updateHireInfo(fieldName: string, value: any) {
        let temp = JSON.parse(
            JSON.stringify({
                ...hireInfo,
                [fieldName]: value,
            })
        );
        setHireInfo(temp);
    }

    function updatePersonalDetails(fieldName: string, value: any) {
        let temp = JSON.parse(
            JSON.stringify({
                ...personalInfo,
                personalDetails: {
                    ...personalInfo.personalDetails,
                    [fieldName]: value,
                },
            })
        );
        setPersonalInfo(temp);
    }

    function homeDestinationAddressDetails(fieldName: string, value: any) {
        let temp = JSON.parse(
            JSON.stringify({
                ...personalInfo,
                homeDestinationAddress: {
                    ...personalInfo.homeDestinationAddress,
                    [fieldName]: value,
                },
            })
        );
        setPersonalInfo(temp);
    }

    function bulkHomeDestinationAddressUpdate(homeAddressObject: any) {
        let temp = JSON.parse(
            JSON.stringify({
                ...personalInfo,
                homeDestinationAddress: homeAddressObject,
            })
        );
        setPersonalInfo(temp);
    }

    function updateDepartureAddress(fieldName: string, value: any) {
        let temp = JSON.parse(
            JSON.stringify({
                ...personalInfo,
                departureAddress: {
                    ...personalInfo.departureAddress,
                    [fieldName]: value,
                },
            })
        );
        setPersonalInfo(temp);
    }

    function updateDestinationAddress(fieldName: string, value: any) {
        let temp = JSON.parse(
            JSON.stringify({
                ...personalInfo,
                workDestinationAddress: {
                    ...personalInfo.workDestinationAddress,
                    [fieldName]: value,
                },
                homeDestinationAddress: {
                    ...personalInfo.homeDestinationAddress,
                    [fieldName]: value,
                },
            })
        );
        setPersonalInfo(temp);
    }

    function updateEmploymentInfo(fieldName: string, value: any) {
        let temp = JSON.parse(
            JSON.stringify({
                ...employmentInfo,
                [fieldName]: value,
            })
        );
        setEmploymentInfo(temp);
    }

    function updateJobLevelInfo(jobLevelObj: any) {
        let temp = JSON.parse(
            JSON.stringify({
                ...employmentInfo,
                jobLevel: jobLevelObj.jobLevel,
                jobLevelID: jobLevelObj.jobLevelID,
            })
        );
        setEmploymentInfo(temp);
    }

    function updateRelocationComments(value: any) {
        set_Comments(value);
    }

    function updateSelectedException(value: any) {
        setSelectedException(value);
    }

    function updateLumSumNetCash(value: any) {
        _lumpSumRelocation.NetCash = parseInt(value);
    }

    function updateLumSumAccuralCost(value: any) {
        var accrualCostCenter = 0;
        var netCashVal = removeCommaOrDollar(value);
        var taxNetCashVal = 100.0;
        taxNetCashVal = taxNetCashVal + _taxPctForLumpSum;
        accrualCostCenter = (netCashVal * taxNetCashVal) / 100;
        _lumpSumRelocation.AccrualCostCenter = parseInt(accrualCostCenter.toFixed(2));
    }

    function updatePreHireSelectedBenefits(selectedPreHireBenefits: PreHireTypeBenefits[]) {
        let selectedBenefits: PreHireTypeBenefits[] = [];
        selectedPreHireBenefits.forEach((item) => {
            selectedBenefits.push(item);
        });
        set_SelectedPreHireTypeBenefits(selectedBenefits);
    }

    function updateRelocationCashAmt(value: any) {
        set_RelocationCashAmount(value);
    }

    function isNotNullOrUndefined(obj: any) {
        if (obj !== undefined && obj !== null) {
            return true;
        }
        return false;
    }

    function changeJobLevel() {
        set_RelocationpolicyTypeID(
            reloFormDataResponse[0].RelocationpolicyTypeID !== null ||
                reloFormDataResponse[0].RelocationpolicyTypeID !== undefined ||
                reloFormDataResponse[0].RelocationpolicyTypeID !== ''
                ? parseInt(reloFormDataResponse[0].RelocationpolicyTypeID)
                : undefined
        );
        set_RelocationPolicyID(
            reloFormDataResponse[0].RelocationPolicyID !== null ||
                reloFormDataResponse[0].RelocationPolicyID !== undefined ||
                reloFormDataResponse[0].RelocationPolicyID !== ''
                ? parseInt(reloFormDataResponse[0].RelocationPolicyID)
                : undefined
        );
        LoadRelocationType({} as IDropdownOption);
        setShowBenefitsPanel(false);
        setShowLumpSumPanel(false);
        setSelectedRelocationCashAmount(0);
        set_LumpSumRelocation({} as LumpSumRelocation);
        if (
            isNotNullOrUndefined(_relocationpolicyTypeID) &&
            isNotNullOrUndefined(_relocationPolicyID) &&
            hireInfo.hireTypeID != 0
        ) {
            loadLumpSumAmount(_relocationpolicyTypeID!, hireInfo.hireTypeID, _relocationPolicyID!);
        }
    }

    async function changeReloType(reloTypeID: number) {
        set_RelocationpolicyTypeID(reloTypeID);
        let fiscalYear;
        set_PassExceptionList([]);
        let date = new Date();
        if (isValidString(employmentInfo.estimatedStartDate)) {
            date = new Date(employmentInfo.estimatedStartDate);
        }
        if (date.getMonth() > 5) {
            fiscalYear = date.getFullYear() + 1;
        } else {
            fiscalYear = date.getFullYear();
        }
        if (reloTypeID != 0 && reloTypeID !== null && reloTypeID !== undefined) {
            let selectedReloType: RelocationType = reloTypes.filter((item) => {
                return parseInt(item.RelocationTypeID) == reloTypeID;
            })[0];
            if (isNotNullOrUndefined(selectedReloType) && selectedReloType.HireTypeID == 7) {
                await getPreHireTypeBenefits();
                setShowLumpSumPanel(false);
                setShowBenefitsPanel(false);
                setShowPreHireTypePanel(true);
                set_FiscalYear(fiscalYear);
            } else {
                if (isNotNullOrUndefined(selectedReloType) && selectedReloType.ReloPolicyType != null) {
                    if (selectedReloType.ReloPolicyType == 'LumSum') {
                        setShowLumpSumPanel(true);
                        setRelocationType(selectedReloType.RelocationTypeName);
                        setShowPreHireTypePanel(false);
                        if(selectedReloType.RelocationTypeName.includes("Plus")){
                            setShowBenefitsPanel(true);
                            setShowOptionalException(false);
                            await getCoreBenefits(reloTypeID);
                        }else{
                            setShowBenefitsPanel(false);
                            set_CoreBenefits([]);
                        }
                        setOptionalBenefitMapping([]);
                        set_AllOptionalBenefits(
                            optionalBenefitMapping.map((option) => {
                                return { ...option, isSelected: false };
                            })
                        );
                        setExceptionMapping([]);
                        set_FiscalYear(fiscalYear);
                        loadLumpSumAmount(_relocationpolicyTypeID!, hireInfo.hireTypeID, _relocationPolicyID!);
                    } else {
                        setShowLumpSumPanel(false);
                        setShowBenefitsPanel(true);
                        setShowOptionalException(true);
                        set_FiscalYear(fiscalYear);
                        set_LumpSumRelocation({} as LumpSumRelocation);
                        await getCoreBenefits(reloTypeID);
                        await getExceptions(parseInt(selectedReloType.RelocationTypeID));
                        if (isNotNullOrUndefined(selectedReloType)) {
                            await getAllOptionalBenefits(
                                selectedReloType.ReloPolicyType,
                                parseInt(selectedReloType.RelocationTypeID)
                            );
                        }
                    }
                } else {
                    setShowBenefitsPanel(false);
                }
            }
            if (reloTypeID == 36) {
                setShowLumpSumPanel(false);
                setShowBenefitsPanel(false);
                set_RelocationCashAmount(0);
                await getExceptions(reloTypeID);
            }
            else if(reloTypeID == 54 || reloTypeID == 55){
                setShowOptionalException(false);
            }
        } else {
            setShowLumpSumPanel(false);
            setShowBenefitsPanel(false);
        }
        set_policyReloTypeChanged(true);
    }

    async function getPreHireTypeBenefits() {
        let currentPreHireTypeBenefits: any[] = [];
        let selectedPreHireTypeBenefits: any[] = [];
        currentPreHireTypeBenefits = await startNewReloRequestService.GetPreHireTypeBenefits();
        currentPreHireTypeBenefits.forEach((item: { isSelected: boolean; ID: number }) => {
            item.isSelected = false;
            if (isNotNullOrUndefined(_preHireTypeBenefitsMapping) && _preHireTypeBenefitsMapping.length > 0) {
                _preHireTypeBenefitsMapping.forEach((option) => {
                    if (item.ID == option.SelectedPreHireTypeBenefitID) {
                        item.isSelected = true;
                    }
                    selectedPreHireTypeBenefits.push(option);
                });
            }
        });
        setPreHireTypeBenefits(currentPreHireTypeBenefits);
        set_SelectedPreHireTypeBenefits(selectedPreHireTypeBenefits);
    }

    async function loadLumpSumAmount(ReloTypeID: number, HireTypeID: number, RelocationPolicyID: number) {
        let lumpSumPolicyTypes = await startNewReloRequestService.GetLumpSumRelocationTypes(_fiscalYear!);
        let selectedJobLevel = employmentInfo.jobLevelOptions.filter(
            (item) => item.key == employmentInfo.jobLevelID
        )[0];
        let lumpSumJobLevel = '';
        if (isNotNullOrUndefined(selectedJobLevel)) {
            lumpSumJobLevel = 'N/A';
            if (selectedJobLevel.key == '1') {
                lumpSumJobLevel = 'Below L60';
            } else if (
                selectedJobLevel.key == '2' ||
                selectedJobLevel.key == '3' ||
                selectedJobLevel.key == '4' ||
                selectedJobLevel.key == '5' ||
                selectedJobLevel.key == '11'
            ) {
                lumpSumJobLevel = 'L60-L64';
            } else if (selectedJobLevel.key == '6' || selectedJobLevel.key == '7' || selectedJobLevel.key == '8') {
                lumpSumJobLevel = 'L65-L67';
            } else if (selectedJobLevel.key == '9' || selectedJobLevel.key == '10') {
                lumpSumJobLevel = 'Above L68';
            }
        }
        if (ReloTypeID == 28 || ReloTypeID == 24 || ReloTypeID == 25 || ReloTypeID == 52 || ReloTypeID == 59) {
            lumpSumPolicyTypes = lumpSumPolicyTypes.filter(
                (item: { RelocationTypeID: number }) => item.RelocationTypeID == ReloTypeID
            );
        } else {
            lumpSumPolicyTypes = lumpSumPolicyTypes.filter(
                (item: { RelocationTypeID: number; JobLevel: string; HireTypeID: number }) => {
                    return (
                        item.RelocationTypeID == ReloTypeID &&
                        item.JobLevel == lumpSumJobLevel &&
                        item.HireTypeID == HireTypeID
                    );
                }
            );
            if (HireTypeID != 4 && selectedJobLevel != undefined) {
                let tax = taxByJobLevelsResponse.filter((item) => {
                    return item.JobLevelId == selectedJobLevel.key && item.Fiscal_Year == _fiscalYear;
                })[0];
                set_TaxPctForLumpSum(tax?.TaxRate || 0);
            } else if (lumpSumPolicyTypes && lumpSumPolicyTypes.length > 0) {
                set_TaxPctForLumpSum(lumpSumPolicyTypes[0].TaxRateForLumpSum);
            }
        }
        let savedNetcash = 0;
        let savedAccCostCenter = 0;
        if (isNotNullOrUndefined(lumpSumPolicyTypes) && lumpSumPolicyTypes.length > 0) {

            if (
                _lumpSumRelocation != null &&
                (_formStatus?.toLowerCase() == 'saved' ||
                    _formStatus?.toLowerCase() == 'ready to initiate' ||
                    _formStatus?.toLowerCase() == 'resubmitted')
            ) {
                if (!isSavedAlready) {
                    setIsSavedAlready(true);
                    savedNetcash = _lumpSumRelocation.NetCash;
                    savedAccCostCenter = parseFloat(
                        ((_lumpSumRelocation.NetCash * _taxPctForLumpSum) / 100 + _lumpSumRelocation.NetCash).toFixed(2)
                    );
                }
            }
            set_LumpSumRelocation({} as LumpSumRelocation);
            if (!isNotNullOrUndefined(_lumpSumRelocation)) {
                let lumpSumRelo: LumpSumRelocation = {
                    ID: 0,
                    RelocationPolicyTypeID: ReloTypeID!,
                    ReloID: hireInfo.reloID,
                    NetCash:
                        savedNetcash == 0
                            ? parseFloat(lumpSumPolicyTypes[0].NetCash.replace(/$/g, '').replace(/,/g, ''))
                            : savedNetcash,
                    AccrualCostCenter:
                        savedAccCostCenter == 0
                            ? parseFloat(
                                (
                                    (parseFloat(lumpSumPolicyTypes[0].NetCash.replace(/$/g, '').replace(/,/g, '')) *
                                        _taxPctForLumpSum) /
                                    100 +
                                    parseFloat(lumpSumPolicyTypes[0].NetCash.replace(/$/g, '').replace(/,/g, ''))
                                ).toFixed(2)
                            )
                            : savedAccCostCenter,
                    CreatedBy: userdata.Alias,
                    CreatedOn: new Date().toString(),
                    IsRemoved: false,
                    TotalPackageValue: 0,
                    FileFee: 0,
                    TaxRate: 0,
                };
                set_LumpSumRelocation(lumpSumRelo);
            }
        }
    }

    async function getCoreBenefits(reloTypeId: number) {
        let fiscal_Year = _fiscalYear;
        if (fiscal_Year === undefined || fiscal_Year === 0) {
            fiscal_Year = new Date().getFullYear();
        }

        let coreBenefits: any[] = [];

        let coreBenefitsResponse = await reviewFormService.getCoreBenefits(reloTypeId, fiscal_Year);

        let coreAllowance: any[] = [];
        let coreTaxRate = '';
        let selectedJobLevel = jobLevelInfoResponse.filter(
            (item) => item.ID == employmentInfo.jobLevelID
        )[0];
        if (hireInfo.hireTypeID != 4 && selectedJobLevel != undefined) {
            let tax = taxByJobLevelsResponse.filter((item) => {
                return item.JobLevelId == Number(selectedJobLevel.ID) && item.Fiscal_Year == fiscal_Year;
            })[0];
            coreTaxRate = tax?.TaxRate?.toString() || '';
        }

        let filterResult = coreBenefitsResponse.filter((e: any) => {
            if (e.CoreBenifitID == 12 && hireInfo.hireTypeID != 4) {
                e.Amount = 'Included @ ' + coreTaxRate + '%';
            }
            if (e.CoreBeniftName.trim() == 'Relocation Cash Allowance') {
                if (
                    e.RelocationPolicyTypeID === 19 ||
                    e.RelocationPolicyTypeID === 20 ||
                    e.RelocationPolicyTypeID === 21 ||
                    e.RelocationPolicyTypeID === 37
                ) {
                    if (e.CoreBenifitID == 3) {
                        coreAllowance.push({
                            CoreBenifitID: 0,
                            CashAmount: e.Amount,
                            IsLessThan: parseFloat(e.Amount) <= 10000 ? true : false,
                            CoreBeniftName: 'Relocation Cash Allowance',
                            IsDisabled: true,
                        });
                        e.Amount = '$' + e.Amount.toString();
                    }
                    return e;
                } else {
                    coreAllowance.push({
                        CoreBenifitID: 0,
                        CashAmount: e.Amount,
                        IsLessThan: parseFloat(e.Amount) <= 10000 ? true : false,
                        CoreBeniftName: 'Relocation Cash Allowance',
                        IsDisabled: false,
                    });
                }
                let reloCashAmt = 0;
                if (
                    (e.RelocationPolicyTypeID === 39 ||
                        e.RelocationPolicyTypeID === 12 ||
                        e.RelocationPolicyTypeID === 13 ||
                        e.RelocationPolicyTypeID === 18) &&
                    e.CoreBenifitID == 3
                ) {
                    reloCashAmt = e.Amount;
                } else {
                    reloCashAmt = hireInfo.reloID != 0 ? selectedRelocationCashAmount : 0;
                }
                set_RelocationCashAmount(reloCashAmt);
            }
            return e.CoreBeniftName.trim() != 'Relocation Cash Allowance';
        });
        if (coreAllowance.length == 0) {
            set_RelocationCashAmount(0);
        }
        /*
        As lump sum have multiple repeated core benefits for different levels
        for that we only grab the first one.
        */
        if(reloTypeId == 57 || reloTypeId == 59){
            filterResult = filterResult.slice(0,1);
        }
        coreBenefits = filterResult;
        set_CoreBenefits(coreBenefits);
        set_CoreAllowance(coreAllowance);
        set_FiscalYear(fiscal_Year);
    }

    async function getExceptions(relocationpolicyTypeID: number) {
        const exceptionsResponse = (await reviewFormService.getExceptions(relocationpolicyTypeID))
            .map((item) => ({ ...item, Unit: '', Amount: '' }))
            .filter((item) => item.OptionalBenifitID === 0);

        const exceptionsDropDownOptions: IDropdownOption[] = exceptionsResponse.map(item => ({ key: item.ExceptionID, text: item.ExceptionName }))

        if (exceptionMapping !== null && exceptionMapping !== undefined) {
            let gridExceptions: ReloException[] = [];
            exceptionMapping.forEach((exc) => {
                let selectedException = (exceptionsResponse.filter((item) => item.ExceptionID == exc.ExceptionID))[0];
                if (isNotNullOrUndefined(selectedException)) {
                    let obj2: ReloException = {
                        ReloID: reloFormDataResponse[0].ReloID,
                        ExceptionName: selectedException.ExceptionName,
                        ExceptionID: selectedException.ExceptionID,
                        Unit: exc.Unit,
                        IsDeleted: selectedException.IsAvailable,
                        CustFileID: 0,
                        Amount: exc.Amount,
                        TotalAmount: selectedException.Actual_Amount,
                        IsPostAccrual: false,
                        UnitType: selectedException.UnitType,
                        Remove: '',
                    };
                    gridExceptions.push(obj2);
                }
            });
            set_PassExceptionList(gridExceptions);
        }
        setExceptionsResponse(exceptionsResponse);
        setExceptionOptions(exceptionsDropDownOptions);
    }

    async function getAllOptionalBenefits(reloType: string, relocationPolicyTypeID: number) {
        await getOptionalBenefits(relocationPolicyTypeID, reloType);
        await getExceptions(relocationPolicyTypeID);
    }

    async function getOptionalBenefits(RelocationPolicyTypeID: number, ReloPolicyType: string) {
        const allOptionalBenefits = (await startNewReloRequestService.GetRelocationOptionalBenefits(RelocationPolicyTypeID))
            .filter((item, index, self) => self.findIndex((item2) => item.OptionalBenifitID === item2.OptionalBenifitID) === index);

        const allOptionalBenefitsMaxAmounts = (await startNewReloRequestService
            .GetAllBenefits(ReloPolicyType))
            .map(item => ({ OptionalBenifitID: item.OptionalBenifitID, MaxAmount: item.MaxAmount }));

        const options = allOptionalBenefits.map((column: any) => {
            column.UnitMaxValue = (column.Unit?.replace(/[^0-9]/gm, '').includes("2")) ? '2' : '1000';
            column.UnitType = (column?.UnitType === "Free Field") ? column.UnitType.replace(/\s/g, '') : column.UnitType;
            let findRecord = allOptionalBenefitsMaxAmounts.find((optionalBenefitsItem) => optionalBenefitsItem.OptionalBenifitID === column.OptionalBenifitID);
            column.MaxAmount = (findRecord?.MaxAmount) ? findRecord.MaxAmount : '1500';

            findRecord = optionalBenefitMapping?.find((item) => item.OptionalBenifitID === column.OptionalBenifitID);
            if (findRecord !== undefined && findRecord !== null) {
                column.disableStatus = false;
                if (column.UnitType === 'Unit') {
                    column.Unit = findRecord.Unit;
                } else if (column.UnitType === 'FreeField') {
                    column.Amount = findRecord.Amount;
                }
            } else {
                column.disableStatus = true;
                column.Unit = (isNaN(Number(column.Unit))) ? '' : column.Unit;
                column.Amount = (isNaN(Number(column.Amount))) ? '' : column.Amount;
            }
            column.isSelected = !column.disableStatus;

            return column;
        });

        set_AllOptionalBenefits(options);
    }

    function handleExceptionUnitValueUpdate(index: number, unitType: string, unitValue: string) {
        let obj = _passExceptionList;
        obj[index] = { ...obj[index], [unitType]: (unitValue !== '' && !isNaN(Number(unitValue)) && 0 <= Number(unitValue)) ? (Number(unitValue)) : '' };
        set_PassExceptionList(obj);
        setExceptionListUpdatedFlag(exceptionListUpdatedFlag! + 1);
    }

    function handleOptionalBenefitUnitUpdate(benefit: OptionalBenefitsType[]) {
        let updatedBenefits = _allOptionalBenefits.map(function (x) {
            let result = benefit.filter(
                (a1) => a1.OptionalBenifitID === x.OptionalBenifitID && a1.disableStatus == false
            );
            if (result.length > 0) {
                if (x?.UnitType === "Unit") {
                    x.Unit = result[0].Unit;
                } else if (x?.UnitType === "FreeField") {
                    x.Amount = result[0].Amount;
                }
                x.disableStatus = false;
            } else {
                x.disableStatus = true;
            }
            return x;
        });
        set_AllOptionalBenefits(updatedBenefits);
    }

    function handleRemoveException(index: number) {
        let allExceptions = _passExceptionList;
        allExceptions = allExceptions.slice(0, index).concat(allExceptions.slice(index + 1));
        let exNames = exceptionNames;
        exceptionNames = exNames.slice(0, index).concat(exNames.slice(index + 1));
        set_PassExceptionList(allExceptions);
        setExceptionListUpdatedFlag(exceptionListUpdatedFlag! + 1);
    }

    function handleAddException() {
        _passExceptionList.forEach((element) => {
            if (exceptionNames.indexOf(element.ExceptionName) == -1) {
                exceptionNames.push(element.ExceptionName);
            }
        });

        if (exceptionNames.indexOf(selectedException) == -1 && selectedException !== '') {
            exceptionsResponse.find((elem) => {
                if (elem.ExceptionName == selectedException) {
                    exceptionNames.push(selectedException);
                    let obj1 = _passExceptionList;
                    let obj2: ReloException = {
                        ...elem,
                        ReloID: reloFormDataResponse[0].ReloID,
                        ExceptionName: selectedException,
                        CustFileID: 0,
                        TotalAmount: elem.Actual_Amount,
                        IsPostAccrual: false,
                        Remove: '',
                    };

                    obj1.push(obj2);
                    set_PassExceptionList([...obj1]);
                    setExceptionListUpdatedFlag(exceptionListUpdatedFlag! + 1);
                }
            });
        } else {
            if (selectedException == '') {
                alert('Select Exception');
            } else {
                alert('This exeption alredy exists...');
            }
        }
    }

    function getAttachmentsToSaveOrSubmit() {
        let emptyReloSummary = {
            ReloID: hireInfo.reloID,
            CreatedBy: userdata.Alias,
            CreatedDate: new Date(),
            AttachReloSummaryID: 0,
            AddtionalDescription: '',
            Filename: null,
            IsRemoved: true,
        };

        return {
            AttachReloSummary:
                Object.keys(_requiredReloSummary).length > 0
                    ? _requiredReloSummary
                    : {
                        ...emptyReloSummary,
                        IsAdditional: 10,
                    },
            AdditonalReloSummary0:
                Object.keys(_additionalReloSummary0).length > 0
                    ? _additionalReloSummary0
                    : {
                        ...emptyReloSummary,
                        IsAdditional: 0,
                    },
            AdditonalReloSummary1:
                Object.keys(_additionalReloSummary1).length > 0
                    ? _additionalReloSummary1
                    : {
                        ...emptyReloSummary,
                        IsAdditional: 1,
                    },
            AdditonalReloSummary2:
                Object.keys(_additionalReloSummary2).length > 0
                    ? _additionalReloSummary2
                    : {
                        ...emptyReloSummary,
                        IsAdditional: 2,
                    },
            AdditonalReloSummary3:
                Object.keys(_additionalReloSummary3).length > 0
                    ? _additionalReloSummary3
                    : {
                        ...emptyReloSummary,
                        IsAdditional: 3,
                    },
            AdditonalReloSummary4:
                Object.keys(_additionalReloSummary4).length > 0
                    ? _additionalReloSummary4
                    : {
                        ...emptyReloSummary,
                        IsAdditional: 4,
                    },
            AttachmentGUID: _attachmentGUID !== '' || null ? _attachmentGUID : null,
            AttachmentGUID0: _attachmentGUID0 !== '' || null ? _attachmentGUID0 : null,
            AttachmentGUID1: _attachmentGUID1 !== '' || null ? _attachmentGUID1 : null,
            AttachmentGUID2: _attachmentGUID2 !== '' || null ? _attachmentGUID2 : null,
            AttachmentGUID3: _attachmentGUID3 !== '' || null ? _attachmentGUID3 : null,
            AttachmentGUID4: _attachmentGUID4 !== '' || null ? _attachmentGUID4 : null,
        };
    }

    function getEmploymentInfoToSaveOrSubmit() {
        return {
            HRStaffinggroup: employmentInfo.hrStaffingGroup != '' ? employmentInfo.hrStaffingGroup : '',
            HRStaffingManagerAlias: employmentInfo.hRStaffingManagerAlias,
            HRRecruitingAssociate: employmentInfo.hRRecruitingAssociate,
            HiringManagerAlias: employmentInfo.hiringManagerAlias,
            HRStaffingGroupAlias: employmentInfo.hrStaffingGroup,
            HRRecruiterAlias: employmentInfo.hRRecruiterAlias,
            CostCenter: employmentInfo.costCenter,
            EstimatedEndDate: employmentInfo.estimatedEndDate
                ? moment(employmentInfo.estimatedEndDate).format('YYYY-MM-DD')
                : null,
            EstimatedStartDate: employmentInfo.estimatedStartDate
                ? moment(employmentInfo.estimatedStartDate).format('YYYY-MM-DD')
                : null,
            BusinessGroup: employmentInfo.businessGroup,
            JobLevelID: employmentInfo.jobLevelID,
            RushComments: employmentInfo.RushComments,
            DestinationCompanyCode: employmentInfo.DestinationCompanyCode != null ? employmentInfo.DestinationCompanyCode : 0,
            DepartureCompanyCode: employmentInfo.DepartureCompanyCode != null ? employmentInfo.DepartureCompanyCode : 0,
            RequisitionID: employmentInfo.RequisitionID != null ? employmentInfo.RequisitionID : 0
        };
    }

    function getPersonalInforToSaveOrSubmit() {
        return {
            DepartureAddress: {
                ...personalInfo.departureAddress,
                Apartment:
                    personalInfo.departureAddress.Apartment !== undefined
                        ? personalInfo.departureAddress.Apartment
                        : null,
                PostalCode: parseInt(personalInfo.departureAddress.PostalCode),
            },
            DestinationAddress: {
                ...personalInfo.homeDestinationAddress,
                ReloID: hireInfo.reloID,
            },
            WorkDestinationCountry: personalInfo.workDestinationAddress.Country,
            WorkDestinationCity: personalInfo.workDestinationAddress.City,
            WorkDestinationStateProvince: personalInfo.workDestinationAddress.StateProvince,
        };
    }


    function getReloFormToSaveOrSubmit(isSubmit: boolean) {
        const attachmentInfoToSaveOrSubmit = getAttachmentsToSaveOrSubmit();
        const employeeInfoToSaveOrSubmit = getEmploymentInfoToSaveOrSubmit();
        const personalInfoToSaveOrSubmit = getPersonalInforToSaveOrSubmit();
        const hireInfoToSaveOrSubmit = getHireInfoToSaveOrSubmit();

        const selectedGridExceptions = getSelectedExceptions();
        const selectedOptionalBenefits = getSelectedOptionalBenefits();

        return {
            ...attachmentInfoToSaveOrSubmit,
            ...employeeInfoToSaveOrSubmit,
            ...personalInfoToSaveOrSubmit,
            ...hireInfoToSaveOrSubmit,
            OriginAddress: reloFormDataResponse[0].OriginAddress,
            OriginCity: reloFormDataResponse[0].OriginCity,
            OriginStateProvince: reloFormDataResponse[0].OriginStateProvince,
            PostalCode: reloFormDataResponse[0].PostalCode,
            OriginCountry: reloFormDataResponse[0].OriginCountry,
            Comments: _comments,
            RushComments: employmentInfo.RushComments,
            DestinationCompanyCode: employmentInfo.DestinationCompanyCode != null ? employmentInfo.DestinationCompanyCode : 0,
            DepartureCompanyCode: employmentInfo.DepartureCompanyCode != null ? employmentInfo.DepartureCompanyCode : 0,
            RequisitionID: employmentInfo.RequisitionID != null ? employmentInfo.RequisitionID : 0,
            ExceptionMapping: selectedGridExceptions.length > 0 ? selectedGridExceptions : [],
            FiscalYear: _fiscalYear,
            InternshipTypeID: reloFormDataResponse[0].InternshipTypeID,
            IsActive: false,
            IsEdit: _formStatus == 'New' ? false : true,
            IsWentInWebJE: false,
            LimitedBenefitExecutiveTaxPct: 0,
            LoggedInUser: userdata.Alias,
            LumpSumRelocation: _lumpSumRelocation ? _lumpSumRelocation : {},
            OptionalBenefitMapping:
                selectedOptionalBenefits.length > 0 ? selectedOptionalBenefits : [],
            PackageValue: '',
            PersonalDetails: {
                ...personalInfo.personalDetails,
                VisaPending:
                    personalInfo.personalDetails.VisaPending == '' || personalInfo.personalDetails.VisaPending == null
                        ? 'No'
                        : personalInfo.personalDetails.VisaPending,
            },
            PreHireTypeBenefits:
                _selectedPreHireTypeBenefits.length > 0 ? _selectedPreHireTypeBenefits : [],
            PreHireTypeBenefitsMapping: _preHireTypeBenefitsMapping,
            ReadyToReview: 0,
            RelocationCashAmount: _relocationCashAmount,
            RelocationPolicyID: _relocationPolicyID ? _relocationPolicyID!.toString() : '',
            RelocationpolicyTypeID: _relocationpolicyTypeID ? _relocationpolicyTypeID!.toString() : '',
            SendBackComments: _sendBackComments,
            Status: !isSubmit ? 'Saved' : 'Submitted',
            TaxPctForLumpSum: _taxPctForLumpSum,
            TotalEstimate: reloFormDataResponse[0].TotalEstimate,
            TotalPackageValue: reloFormDataResponse[0].TotalPackageValue,
            UpdatedBySyncJob: reloFormDataResponse[0].UpdatedBySyncJob,
            DepartureStatus: reloFormDataResponse[0].DepartureStatus,
            PreHireTypeBenefitsList: [],
            CurrentDate: new Date().toString(),
            StartDate: '0001-01-01T00:00:00',
            CustFileID: reloFormDataResponse[0].CustFileID,
            SlackAmount: reloFormDataResponse[0].SlackAmount,
            coreBenefits: _coreBenefits,
            CoreAllowance: _coreAllowance,
            file: {},
            fileupload: {},
        };
    }

    // Save and Submit implementation
    async function OnSaveClick(isSubmit: boolean) {
        let formData: any = getReloFormToSaveOrSubmit(isSubmit);

        if (!isNotNullOrUndefined(formData)) {
            alert('Please Enter a Valid Data Before Submitting The Form');
        } else {
            if (formData.HireTypeID != 6 && formData.HireTypeID != 7) {
                if (!isValidString(formData.CandidateID) ||
                    parseInt(formData.CandidateID) == 0
                ) {
                    if (!isValidString(formData.EmployeeID.toString()) ||
                        formData.EmployeeID == 0
                    ) {
                        alert(
                            'Please Enter a Valid Candidate ID or Employee ID or HireType Before Submitting The Form'
                        );
                    } else {
                        await saveRelocationFormData(isSubmit, formData);
                    }
                } else {
                    await saveRelocationFormData(isSubmit, formData);
                }
            } else {
                await saveRelocationFormData(isSubmit, formData);
            }
        }
    }

    async function OnUpdateClick() {
        if (errorFields.length === 0) {
            await OnSaveClick(true);
            if (_formStatus == 'SAVED') {
                let obj = JSON.stringify({
                    recordID: props.recordID,
                    recordStatus: props.recordStatus,
                    ActionType: 'GLOMOCO',
                });
                history.push('/ReviewForm', obj);
            } else {
                setApiError(true);
                setMessageText('Update was Unsuccessfull');
            }
        } else {
            alert('Validation Failed');
        }
    }

    async function saveRelocationFormData(isSubmit: boolean, formData: IReviewForm) {
        let d = new Date(formData.EstimatedStartDate!);
        if (!isNotNullOrUndefined(formData.EstimatedStartDate)) {
            d = new Date();
        }
        if (d.getMonth() > 5) {
            formData.FiscalYear = d.getFullYear() + 1;
            set_FiscalYear(formData.FiscalYear);
        } else {
            formData.FiscalYear = d.getFullYear();
            set_FiscalYear(formData.FiscalYear);
        }
        formData.LoggedInUser = userdata.Alias;
        if (!isSubmit) {
            formData.Status = 'Saved';
        } else {
            formData.Status = 'Submitted';
        }
        if (parentStatus == 'SENDBACK' || parentStatus == 'RESUBMITTED' || parentStatus == "RETURN FOR EDITS") {
            formData.Status = 'Resubmitted';
        }
        if (formData.HireTypeID != 4) formData.InternshipTypeID = 0;
        if (formData.HireTypeID == 7) {
            formData.PreHireTypeBenefits =
                _selectedPreHireTypeBenefits.length > 0
                    ? _selectedPreHireTypeBenefits
                    : ({} as PreHireTypeBenefits[]);
        }

        formData.RelocationCashAmount = removeCommaOrDollar(formData.RelocationCashAmount);
        formData.TaxPctForLumpSum = _taxPctForLumpSum;
        setIsLoading(true);

        await startNewReloRequestService
            .SaveRelocationForm(formData)
            .then(
                async function (result) {
                    if (result.status == 201) {
                        formData.ReloID = result.data;
                        setHireInfo((preState) => ({ ...preState, reloID: result.data }));

                        setApiSuccess(true);
                        if (formData.Status.toUpperCase() == 'SAVED') {
                            set_FormStatus('SAVED');
                            setMessageText('Success! This record has been saved to your Relo Queue.');
                        } else if (formData.Status.toUpperCase() == 'SUBMITTED') {
                            setMessageText('Eform has been successfully updated');
                        }
                    } else {
                        setApiError(true);
                        setMessageText('Save NOT SUCCESS');
                    }
                }
            )
            .catch(() => {
                setApiError(true);
                if (formData.Status.toUpperCase() == 'SAVED') {
                    setMessageText('Error! This record was not saved, please try again.');
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
        return formData;

    }

    async function uploadaddfile(file: File, i: number) {
        setIsLoading(true);
        await startNewReloRequestService
            .UploadRelocationSummary(file)
            .then(
                function (resp) {
                    let reloSummary = {
                        ReloID: hireInfo.reloID,
                        CreatedBy: userdata.Alias,
                        AttachReloSummaryID: 0,
                        AddtionalDescription: '',
                        Filename: file.name,
                        IsRemoved: false,
                        CreatedDate: new Date(),
                        IsAdditional: i
                    }
                    switch (i) {
                        case 0:
                            set_AttachmentGUID0(resp);
                            set_AdditionalReloSummary0(reloSummary);
                            break;
                        case 1:
                            set_AttachmentGUID1(resp);
                            set_AdditionalReloSummary1(reloSummary);
                            break;
                        case 2:
                            set_AttachmentGUID2(resp);
                            set_AdditionalReloSummary2(reloSummary);
                            break;
                        case 3:
                            set_AttachmentGUID3(resp);
                            set_AdditionalReloSummary3(reloSummary);
                            break;
                        case 4:
                            set_AttachmentGUID4(resp);
                            set_AdditionalReloSummary4(reloSummary);
                            break;
                    }
                    setApiSuccessRemovedFile0(false);
                    setApiSuccessRemovedFile1(false);
                    setApiSuccessRemovedFile2(false);
                    setApiSuccessRemovedFile3(false);
                    setApiSuccessRemovedFile4(false);
                    setApiSuccess(true);
                    setMessageText(
                        "Success! This attachment has been successfully uploaded. Please don't forget to click on SaveSubmit before leaving this page to complete the transaction."
                    );
                },
                function (err) { }
            )
            .catch(() => {
                setApiError(true);
                setMessageText('Error! This attachment was not uploaded, please try again.');
            })
            .finally(() => {
                setIsLoading(false);
            });
        return;
    }

    async function uploadfile(file: File) {
        setIsLoading(true);
        await startNewReloRequestService
            .UploadRelocationSummary(file)
            .then(
                function (resp) {
                    setApiSuccessRemovedFile0(false);
                    setApiSuccessRemovedFile1(false);
                    setApiSuccessRemovedFile2(false);
                    setApiSuccessRemovedFile3(false);
                    setApiSuccessRemovedFile4(false);
                    let reloSummary = {
                        ReloID: hireInfo.reloID,
                        CreatedBy: userdata.Alias,
                        AttachReloSummaryID: 0,
                        AddtionalDescription: '',
                        Filename: file.name,
                        IsRemoved: false,
                        CreatedDate: new Date(),
                        IsAdditional: 10
                    }
                    set_AttachmentGUID(resp);
                    setApiSuccess(true);
                    set_RequiredReloSummary(reloSummary);
                    setMessageText(
                        "Success! This attachment has been successfully uploaded. Please don't forget to click on Save\\Submit before leaving this page to complete the transaction."
                    );
                },
                function (err) {
                    alert(`File ${file.name} upload failed`);
                }
            )
            .catch(() => {
                setApiError(true);
                setMessageText('Error! This attachment was not uploaded, please try again.');
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    function RemoveSelectedReloFile() {
        set_AttachmentGUID('');
        set_RequiredReloSummary({} as AttachReloSummary);
    }

    function hidemessageBox() {
        setApiError(false);
        setApiSuccess(false);
    }

    function updateAdditionalDescriptionValue(value: string, index: number) {
        switch (index) {
            case 0:
                set_AdditionalReloSummary0({ ..._additionalReloSummary0, AddtionalDescription: value });
                break;
            case 1:
                set_AdditionalReloSummary1({ ..._additionalReloSummary1, AddtionalDescription: value });
                break;
            case 2:
                set_AdditionalReloSummary2({ ..._additionalReloSummary2, AddtionalDescription: value });
                break;
            case 3:
                set_AdditionalReloSummary3({ ..._additionalReloSummary3, AddtionalDescription: value });
                break;
            case 4:
                set_AdditionalReloSummary4({ ..._additionalReloSummary4, AddtionalDescription: value });
                break;
        }
    }

    function RemoveAddSelectedReloFile(index: number) {
        setApiSuccess(false);
        setMessageText('Success! The file has been removed.');
        setApiSuccessRemovedFile0(false);
        setApiSuccessRemovedFile1(false);
        setApiSuccessRemovedFile2(false);
        setApiSuccessRemovedFile3(false);
        setApiSuccessRemovedFile4(false);
        switch (index) {
            case 0:
                set_AttachmentGUID0('');
                set_AdditionalReloSummary0({} as AttachReloSummary);
                setApiSuccessRemovedFile0(true);
                break;
            case 1:
                set_AttachmentGUID1('');
                set_AdditionalReloSummary1({} as AttachReloSummary);
                setApiSuccessRemovedFile1(true);
                break;
            case 2:
                set_AttachmentGUID2('');
                set_AdditionalReloSummary2({} as AttachReloSummary);
                setApiSuccessRemovedFile2(true);
                break;
            case 3:
                set_AttachmentGUID3('');
                set_AdditionalReloSummary3({} as AttachReloSummary);
                setApiSuccessRemovedFile3(true);
                break;
            case 4:
                set_AttachmentGUID4('');
                set_AdditionalReloSummary4({} as AttachReloSummary);
                setApiSuccessRemovedFile4(true);
                break;
        }
    }

    async function onInternshipTypeChange(InternshipTypeID: number) {
        reloFormDataResponse[0].InternshipTypeID = InternshipTypeID;
        if (
            reloFormDataResponse[0].InternshipTypeID != null &&
            reloFormDataResponse[0].InternshipTypeID != undefined
        ) {
            let selectedHireTypes = hireInfo.hireTypesOptions.filter((row) => {
                if (row.key === reloFormDataResponse[0].HireTypeID) {
                    return row.text as string;
                }
            });
            let selectedHireType = selectedHireTypes[0].text;
            if (selectedHireType === 'Intern') {
                if (selectedHireType === 'Intern') {
                    setHiringManagerLabel('Program Manager (GMAT Exception Approver)');
                    if (reloFormDataResponse[0].InternshipTypeID == 5) {
                        setShowHrStaffingManagerAliasTextBox(false);
                        employmentInfo.hrStaffingGroup = 'MSRRC';
                        employmentInfo.hRStaffingManagerAlias = '';
                        employmentInfo.hiringManagerAlias = 'OSSIE';
                        setHRStaffingNameLabel('Staffing Hire Group (For US)/ Staffing Manager (For Non US)');
                    } else if (reloFormDataResponse[0].InternshipTypeID == 6) {
                        setShowHrStaffingManagerAliasTextBox(false);
                        employmentInfo.hiringManagerAlias = '';
                        employmentInfo.hRStaffingManagerAlias = '';
                        employmentInfo.hrStaffingGroup = '';
                    }
                    setShowHrStaffingManagerAliasTextBox(false);
                    employmentInfo.hRStaffingManagerAlias = '';
                }
            }
        }
    }

    function removeCommaOrDollar(amount: any) {
        if (amount && amount.length != 0) {
            if (amount.replace) {
                amount = amount.replace(/$/g, '');
                amount = amount.replace(/,/g, '');
            }
        } else amount = 0;
        return parseFloat(amount);
    }

    async function LoadRelocationType(selectedPolicyType: IDropdownOption) {
        let updatedReloTypes: any[] = [];
        let relocationPolicyTypeIsFull = '';
        let relocationpolicyTypeID: number | undefined =
            isValidString(reloFormDataResponse[0].RelocationpolicyTypeID)
                ? parseInt(reloFormDataResponse[0].RelocationpolicyTypeID)
                : undefined;
        let updatedReloType;
        if (reloFormDataResponse[0].HireTypeID != 4) reloFormDataResponse[0].InternshipTypeID = 0;

        let hireTypeResponse = await reviewFormService.getHireTypeService();

        let hiretypeoptions: IDropdownOption[] = [];
        let newRelocationPolicyTypeOptions: IDropdownOption[] = [];
        hireTypeResponse.forEach((item) => {
            if (item.HireTypeID != 5 && item.HireTypeID != 6)
                hiretypeoptions.push({
                    key: item.HireTypeID,
                    text: item.HireTypeName,
                });
            if (item.HireTypeID == 6)
                hiretypeoptions.push({
                    key: item.HireTypeID,
                    text: 'Executive/ M&A',
                });
        });

        let selectedHireTypes = hiretypeoptions.filter((row) => {
            if (row.key === reloFormDataResponse[0].HireTypeID) {
                return row.text as string;
            }
        });

        let selectedHireType = selectedHireTypes[0].text;

        if (reloFormDataResponse[0].HireTypeID == 4 && reloFormDataResponse[0].InternshipTypeID == 5) {
            employmentInfo.hiringManagerAlias = 'OSSIE';
        }
        if (reloFormDataResponse[0].HireTypeID == 3) {
            if (!isValidString(employmentInfo.hiringManagerAlias)) {
                employmentInfo.hiringManagerAlias = '';
            }
        }

        if (isNotNullOrUndefined(selectedHireType) && selectedHireType == 'Intern') {
            if (!isValidString(employmentInfo.jobLevelID))
                employmentInfo.jobLevelID = '1';

            let selectedInternshipType = hireInfo.hireTypesOptions.filter((item) => {
                if (item.key == reloFormDataResponse[0].InternshipTypeID) {
                    return item;
                }
            })[0];

            if (isNotNullOrUndefined(selectedInternshipType)) {
                hireInfo.showInternship = true;
            }
            if (reloFormDataResponse[0].InternshipTypeID == 5 || reloFormDataResponse[0].InternshipTypeID == 6) {
                setShowHrStaffingManagerAliasTextBox(true);
                employmentInfo.hRStaffingManagerAlias = '';
                if (reloFormDataResponse[0].InternshipTypeID == 5) {
                    employmentInfo.hiringManagerAlias = 'OSSIE';
                    employmentInfo.hrStaffingGroup = 'MSRRC';
                }
            } else {
                setShowHrStaffingManagerAliasTextBox(false);
            }
        } else {
            hireInfo.showInternship = false;
        }

        if (selectedHireType == 'Intern' || selectedHireType == 'University FTE') {
            setHiringManagerLabel('Program Manager (GMAT Exception Approver)');
            setShowHrStaffingManagerAliasTextBox(true);
            if (selectedHireType == 'University FTE') {
                setShowHrStaffingManagerAliasTextBox(false);
                if (!isValidString(employmentInfo.hiringManagerAlias)) {
                    employmentInfo.hiringManagerAlias = '';
                }
            } else {
                if (reloFormDataResponse[0].InternshipTypeID == 5 || reloFormDataResponse[0].InternshipTypeID == 6) {
                    setShowHrStaffingManagerAliasTextBox(false);
                } else {
                    setShowHrStaffingManagerAliasTextBox(false);
                }
            }
        } else {
            setShowHrStaffingManagerAliasTextBox(true);
            setHRStaffingNameLabel('E-form Submitters Manager Alias');
            setHiringManagerLabel('Hiring Manager Alias (Exception Approver)');
        }
        if (isNotNullOrUndefined(hireInfo.hireTypeID) &&
            hireInfo.hireTypeID != 0 &&
            Object.keys(selectedPolicyType).length > 0 &&
            _relocationPolicyID != 0
        ) {
            let resp = await reviewFormService.getrelocationType();
            updatedReloTypes = resp;
            let filteredReloTypesHirePol = updatedReloTypes.filter((type) => {
                return (
                    type.HireTypeID == reloFormDataResponse[0].HireTypeID &&
                    type.RelocationPolicyID == selectedPolicyType?.key
                    && type.IsDeleted == false
                );
            });
            if (reloFormDataResponse[0].HireTypeID === 4) {
                filteredReloTypesHirePol = filteredReloTypesHirePol.filter((item) => {
                    return item.RelocationTypeID != 37;
                });
            }
            let filteredReloTypes = [];
            for (let relType of filteredReloTypesHirePol) {
                if (
                    filteredReloTypes.filter((type) => type.RelocationTypeID == relType.RelocationTypeID).length === 0
                ) {
                    filteredReloTypes.push(relType);
                }
            }

            if (filteredReloTypes !== undefined && filteredReloTypes.length > 0) {
                if (
                    filteredReloTypes.filter((item) => {
                        return item.HireTypeID === 3 && item.RelocationPolicyID === 2;
                    }).length >= 1
                ) {
                    filteredReloTypes = filteredReloTypes.filter((item) => {
                        return item.RelocationTypeID != 12 && item.RelocationTypeID != 13;
                    });
                }
                if (props.ActionType === 'GLOMOCO') {
                    filteredReloTypes.push({
                        "RelocationTypeID": "36",
                        "HireTypeID": 1,
                        "RelocationPolicyID": 2,
                        "RelocationTypeName": "Limited Benefits",
                        "InternshipTypeID": 0,
                        "IsLumpSum": false,
                        "ReloPolicyType": "Full",
                        "TAX": 0
                    })
                }
                updatedReloTypes = filteredReloTypes;

                if (updatedReloTypes.length === 1) {
                    relocationpolicyTypeID = updatedReloTypes[0].RelocationTypeID;
                }
                if (isNotNullOrUndefined(relocationpolicyTypeID)) {
                    changeReloType(relocationpolicyTypeID!);
                    let currentRelocationType = updatedReloTypes.filter((item) => {
                        return item.RelocationTypeID == relocationpolicyTypeID;
                    });
                    relocationPolicyTypeIsFull = '';
                    if (isNotNullOrUndefined(currentRelocationType) && currentRelocationType.length > 0) {
                        updatedReloType = currentRelocationType[0].RelocationTypeName;

                        if (currentRelocationType[0].ReloPolicyType != null) {
                            relocationPolicyTypeIsFull = currentRelocationType[0].ReloPolicyType;
                        }
                    } else {
                        relocationPolicyTypeIsFull = 'Full';
                    }

                    await getAllOptionalBenefits(
                        relocationPolicyTypeIsFull,
                        relocationpolicyTypeID ? relocationpolicyTypeID : 0
                    );
                }
            } else {
                updatedReloTypes = [];
            }

            newRelocationPolicyTypeOptions = updatedReloTypes.map(item => ({ key: item.RelocationTypeID, text: item.RelocationTypeName }));
        } else {
            updatedReloTypes = [];
        }

        set_RelocationPolicyID(selectedPolicyType!.key as number);
        set_RelocationpolicyTypeID(relocationpolicyTypeID);
        setRelocationType(updatedReloType);
        setReloTypes(updatedReloTypes);
        setRelocationPolicyTypeOptions(newRelocationPolicyTypeOptions);
        set_policyReloTypeChanged(true);
    }
};