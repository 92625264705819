import * as React from 'react';
import {
    ExceptionMapping,
    ExceptionsEntity,
    ISendBack,
    jobLevelOptions,
    LumpSumRelocation,
    OptionalBenefitMapping,
    PreHireTypeBenefits,
    RelocationType,
} from '../../Models/IReviewForm';
import { LoadingSpinner } from '../../Components/Shared/LoadingSpinner';
import { useServiceStore } from '../../RootStateContext';
import {
    Separator,
    PrimaryButton,
    IStackStyles,
    Stack,
    mergeStyleSets,
    getTheme,
    FontWeights,
    IIconProps,
    Modal,
    IconButton,
    IDropdownOption,
    IButtonStyles,
    MessageBar,
    MessageBarType,
    Spinner,
    SpinnerSize,
} from '@fluentui/react';
import { DeleteFormPopUp } from './PopUps/DeleteFormPopUp';
import { IUserRoles } from '../../Models/IUserRoles';
import { ReduxContext } from '@employee-experience/common';
import { IAppState } from '../../Common/Redux/AppState';
import { HireType } from '../../Models/IReviewForm';
import { PersonalInformation } from './PersonalInformation';
import { EmploymentInformation } from './EmploymentInformation';
import { RelocationInformation } from './RelocationInformation';
import { useId } from '@fluentui/react-hooks';
import { HireInformation } from './HireInformation';
import { StackDisclaimer } from '../../Components/Shared/DisclaimerText';
import { ActionType } from '../../Common/Redux/actionTypes';
import {
    INewAuthForm,
    EmploymentInfo,
    HireInfo,
    ITaxPerJobLevel,
    OptionalBenefitsType,
    PersonalInfo,
    RelocationInfo,
    ReloException,
    NewAuthFormProps,
    AttachReloSummary
} from '../../Models/INewRelocationRequest';
import { RelocationSummaryAttachment } from './RelocationSummaryAttacment';
import * as moment from 'moment';

// ---------- Styles ----------
const stackStyles: Partial<IStackStyles> = { root: { width: '100%', marginTop: '7px' } };
const theme = getTheme();
const buttonStyles = {
    backgroundColor: 'rgb(0, 120, 212)',
    color: '#FFFFFF',
    marginBottom: '10px',
};
const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: '100em',
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 0 0 0',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

// --------------------------------------------------
export const NewAuthForm: React.FC<NewAuthFormProps> = (props) => {

    const { useSelector, dispatch } = React.useContext(ReduxContext);
    const { reviewFormService, startNewReloRequestService } = useServiceStore();

    const userdata: IUserRoles = useSelector((state: IAppState) => state.root.userReducer.userRoles);

    const [validateOnSubmit, setValidateOnSubmit] = React.useState<boolean>(false);
    const [validateOnReSubmit, setValidateOnReSubmit] = React.useState<boolean>(false);
    const [errorFields, setErrorFields] = React.useState<any[]>([]);
    const [submittedForm, setSubmittedForm] = React.useState<any>();
    const [messageText, setMessageText] = React.useState<string>('');
    const [jobLevelMessage, setJobLevelMessage] = React.useState<boolean>(false);
    const [reloTypes, setReloTypes] = React.useState<any[]>([]);
    const [relocationType, setRelocationType] = React.useState<string>('');
    const [updatedReloForm, setUpdatedReloForm] = React.useState<RelocationInfo>({} as RelocationInfo);

    const [isModalOpen] = React.useState<boolean>(props.isModalOpen);
    const [isDeletePopUpOpen, setIsDeletePopUpOpen] = React.useState<boolean>(false);
    const [isLoaded, setIsLoaded] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isSaved, setIsSaved] = React.useState<boolean>(false);
    const [isSavedAlready, setIsSavedAlready] = React.useState<boolean>(false);
    const [isFormValid, setisFormValid] = React.useState<boolean>(false);
    const [isHireInfoReadOnly, setIsHireInfoReadOnly] = React.useState(false);

    // Resposes from service store
    const [reloFormDataResponse, setReloFormDataResponse] = React.useState<INewAuthForm[]>([]);
    const [exceptionsResponse, setExceptionsResponse] = React.useState<ExceptionsEntity[]>([]);
    const [taxByJobLevelsResponse, setTaxByJobLevelsResponse] = React.useState<ITaxPerJobLevel[]>([]);
    const [jobLevelInfoResponse, setJobLevelInfoResponse] = React.useState<jobLevelOptions[]>([]);

    // Drop down options
    const [relocationPolicyOptions, setRelocationPolicyOptions] = React.useState<IDropdownOption[]>([]);
    const [relocationPolicyTypeOptions, setRelocationPolicyTypeOptions] = React.useState<IDropdownOption[]>([]);
    const [exceptionOptions, setExceptionOptions] = React.useState<IDropdownOption[]>([]);

    // Panels, textbox or buttons to show
    const [showPreHireTypePanel, setShowPreHireTypePanel] = React.useState<boolean>(false);
    const [showBenefitsPanel, setShowBenefitsPanel] = React.useState<boolean>(false);
    const [showOptionalException,setShowOptionalException] = React.useState<boolean>(false);
    const [showLumpSumPanel, setShowLumpSumPanel] = React.useState<boolean>(false);

    const [showHrStaffingManagerAliasTextBox, setShowHrStaffingManagerAliasTextBox] = React.useState<boolean>(false);

    const [showButtons, setShowButtons] = React.useState<boolean>(false);
    const [showSaveButton, setShowSaveButton] = React.useState<boolean>(true);
    const [showSubmitButton, setShowSubmitButton] = React.useState<boolean>(true);

    //Employment Information Fields
    const [hrStaffingNameLabel, setHRStaffingNameLabel] = React.useState<string>('Staffing Manager Alias(GMAT Request Approver)');
    const [hiringManagerLabel, setHiringManagerLabel] = React.useState<string>('Hiring Manager Alias');

    // Benefits information
    const [preHireTypeBenefits, setPreHireTypeBenefits] = React.useState<PreHireTypeBenefits[]>([]);
    const [exceptionListUpdatedFlag, setExceptionListUpdatedFlag] = React.useState<number | null>();
    const [selectedRelocationCashAmount, setselectedRelocationCashAmount] = React.useState<number>(0);
    const [selectedException, setselectedException] = React.useState<string>('');

    // Relo form data to save or update
    const [_taxPctForLumpSum, set_TaxPctForLumpSum] = React.useState<number>(48.5);
    const [_lumpSumRelocation, set_LumpSumRelocation] = React.useState<LumpSumRelocation>({} as LumpSumRelocation);
    const [_formStatus, set_FormStatus] = React.useState<string | null>(null);
    const [_passExceptionList, set_PassExceptionList] = React.useState<ReloException[]>([]);
    const [_allOptionalBenefits, set_AllOptionalBenefits] = React.useState<any[]>([]);
    const [_selectedPreHireTypeBenefits, set_SelectedPreHireTypeBenefits] = React.useState<any[]>([]);
    const [_preHireTypeBenefitsMapping, set_PreHireTypeBenefitsMapping] = React.useState<any[]>([]);
    const [_relocationCashAmount, set_RelocationCashAmount] = React.useState<number>(0);
    const [_relocationPolicyID, set_RelocationPolicyID] = React.useState<number>();
    const [_relocationpolicyTypeID, set_RelocationpolicyTypeID] = React.useState<number>();
    const [_coreBenefits, set_CoreBenefits] = React.useState<any[]>([]);
    const [_coreAllowance, set_CoreAllowance] = React.useState<any[]>([]);
    const [_fiscalYear, set_FiscalYear] = React.useState<number>();
    const [_comments, set_Comments] = React.useState<string>('');
    const [_rushComments, set_rushComments] = React.useState<string>('');
    const [_departureCompanyCode, set_departureCompanyCode] = React.useState<string>('');
    const [_destinationCompanyCode, set_destinationCompanyCode] = React.useState<string>('');
    const [_requisitionID, set_requisitionID] = React.useState<string>('');
    const [_policyReloTypeChanged, set_policyReloTypeChanged] = React.useState<boolean>(false);

    const [_attachmentGUID, set_AttachmentGUID] = React.useState<string>('');
    const [_attachmentGUID0, set_AttachmentGUID0] = React.useState<string>('');
    const [_attachmentGUID1, set_AttachmentGUID1] = React.useState<string>('');
    const [_attachmentGUID2, set_AttachmentGUID2] = React.useState<string>('');
    const [_attachmentGUID3, set_AttachmentGUID3] = React.useState<string>('');
    const [_attachmentGUID4, set_AttachmentGUID4] = React.useState<string>('');

    const emptyAttachReloSummary = {} as AttachReloSummary;
    const [_requiredReloSummary, set_RequiredReloSummary] = React.useState<AttachReloSummary>(emptyAttachReloSummary);
    const [_additionalReloSummary0, set_AdditionalReloSummary0] = React.useState<AttachReloSummary>(emptyAttachReloSummary);
    const [_additionalReloSummary1, set_AdditionalReloSummary1] = React.useState<AttachReloSummary>(emptyAttachReloSummary);
    const [_additionalReloSummary2, set_AdditionalReloSummary2] = React.useState<AttachReloSummary>(emptyAttachReloSummary);
    const [_additionalReloSummary3, set_AdditionalReloSummary3] = React.useState<AttachReloSummary>(emptyAttachReloSummary);
    const [_additionalReloSummary4, set_AdditionalReloSummary4] = React.useState<AttachReloSummary>(emptyAttachReloSummary);

    const [apiError, setApiError] = React.useState<boolean>(false);
    const [apiSuccess, setApiSuccess] = React.useState<boolean>(false);
    const [apiSuccessRemovedFile0, setApiSuccessRemovedFile0] = React.useState<boolean>(false);
    const [apiSuccessRemovedFile1, setApiSuccessRemovedFile1] = React.useState<boolean>(false);
    const [apiSuccessRemovedFile2, setApiSuccessRemovedFile2] = React.useState<boolean>(false);
    const [apiSuccessRemovedFile3, setApiSuccessRemovedFile3] = React.useState<boolean>(false);
    const [apiSuccessRemovedFile4, setApiSuccessRemovedFile4] = React.useState<boolean>(false);

    const [hireInfo, setHireInfo] = React.useState<HireInfo>({
        reloID: 0,
        candidateID: '',
        employeeID: '',
        hireTypeID: 0,
        showInternship: false,
        isMACH: false,
        isMBA: false,
        hireTypesOptions: [],
    });

    const emptyPersonalDetails = {
        CostCenter: '',
        CountryNames: [''],
        CurrentEmail: '',
        EmployeeNumber: 0,
        FirstName: '',
        JobLevel: 0,
        LastName: '',
        MiddleName: '',
        PersonalPhone: '',
        ReloID: 0,
        StartDate: '',
        VisaPending: 'No',
        WorkPhone: '',
        StateNames: [''],
    }
    
    const emptyCityState = {
        City: '',
        Country: '',
        StateProvince: '',
    }

    const emptyAddress = {
        ...emptyCityState,
        Address: '',
        Apartment: '',
        PostalCode: '',
        ReloID: 0,
    }
    const [personalInfo, setPersonalInfo] = React.useState<PersonalInfo>({
        personalDetails: emptyPersonalDetails,
        departureAddress: emptyAddress,
        workDestinationAddress: emptyCityState,
        homeDestinationAddress: emptyCityState,
        countryOptions: [],
        workStateOptions: [],
        departureAddressStateOptions: [],
        destinationAddressStateOptions: [],
        visaOptions: [],
    });

    const [employmentInfo, setEmploymentInfo] = React.useState<EmploymentInfo>({
        businessGroup: '',
        costCenter: '',
        jobLevel: '',
        jobLevelID: '',
        hRStaffingManagerAlias: '',
        hRRecruitingAssociate: '',
        hiringManagerAlias: '',
        estimatedStartDate: '',
        hRRecruiterAlias: '',
        hrStaffingGroup: '',
        estimatedEndDate: '',
        jobLevelOptions: [],
        businessGroupOptions: [],
        RushComments:'',
        DepartureCompanyCode:'',
        DestinationCompanyCode:'',
        RequisitionID:''
    });

    let EstimatedStartDateLabel: string = 'Estimated Start Date';
    let AttachRelocationSummaryText: string = 'Attach Relocation Summary';

    let exceptionNames: string[] = [];

    React.useEffect(() => {
        setReloFormDataResponse(props.newAuthFormData);
    }, []);

    React.useEffect(() => {
        let separate = document.querySelectorAll("div");
        separate.forEach((item) => {
            if (item.getAttribute('role') == 'separator') {
                item.setAttribute('title', 'Separator');
            }
        });
    })

    React.useEffect(() => {
        if (employmentInfo.jobLevel === "Select One") {
            changeJobLevel();
            set_TaxPctForLumpSum(0);
            setJobLevelMessage(false);
        } else {
            let selectedJoblevel = employmentInfo.jobLevel === 'Internship' ? 'Interns' : employmentInfo.jobLevel;
            let taxDetails = taxByJobLevelsResponse.filter((item) => item.JobLevelName === selectedJoblevel);
            if (taxDetails.length == 0) {
                set_TaxPctForLumpSum(0);
            }
            else {
                set_TaxPctForLumpSum(Number(taxDetails[0].TaxRate));
                changeJobLevel();
            }
            setJobLevelMessage(true);
        }
    }, [employmentInfo.jobLevel])

    React.useEffect(() => {
        (async function getReloformData() {
            if (reloFormDataResponse !== null && reloFormDataResponse !== undefined) {
                let status = reloFormDataResponse[0]?.Status;
                set_FormStatus(isNotNullOrUndefined(status) ? 'SAVED' : 'New');
                await getHireInfo(reloFormDataResponse);
                await getPersonalInfo(reloFormDataResponse);
                await getEmploymentInfo(reloFormDataResponse);
                await getRelocationInfo(reloFormDataResponse);
                await GetTaxRatesByJobLevel();
                setShowButtons(true);
                setIsHireInfoReadOnly(true);
                setShowSaveButton(true);
                setShowSubmitButton(true);
                if (parseInt(props.hireTypeOption) == 4) {
                    onInternshipTypeChange(reloFormDataResponse[0].InternshipTypeID);
                }
                LoadRelocationType({} as IDropdownOption);

                if (props.hireTypeOption == '7') {
                    EstimatedStartDateLabel = 'Date of Pre-hire Services';
                    AttachRelocationSummaryText = 'Attach Travel Itinerary';
                }
                setIsLoaded(true);
            }
        })();
    }, [reloFormDataResponse]);

    async function GetTaxRatesByJobLevel() {
        const taxRatesResponse = await startNewReloRequestService.GetTaxRatesByJobLevel();
        dispatch({
            type: ActionType.SET_TAXRATESBYJOBLEVEL,
            data: taxRatesResponse,
        });
        setTaxByJobLevelsResponse(taxRatesResponse);
    }

    async function getHireInfo(data: INewAuthForm[]) {
        const hireTypeResponse = await reviewFormService.getHireTypeService();
        let hiretypeoptions: IDropdownOption[] = [];
        hireTypeResponse.forEach((item) => {
            if (item.HireTypeID != 5 && item.HireTypeID != 6)
                hiretypeoptions.push({
                    key: item.HireTypeID,
                    text: item.HireTypeName,
                });
            if (item.HireTypeID == 6)
                hiretypeoptions.push({
                    key: item.HireTypeID,
                    text: 'Executive/ M&A',
                });
        });
        let hireType = hireTypeResponse.filter((row: HireType) => {
            return row.HireTypeID === parseInt(props.hireTypeOption);
        });
        const hireInfo = {
            reloID: data[0].ReloID,
            candidateID: data[0].CandidateID != null && data[0].CandidateID != '' ? data[0].CandidateID : '',
            employeeID: data[0].EmployeeID != null && data[0].EmployeeID != 0 ? data[0].EmployeeID.toString() : '',
            showInternship: hireType[0].HireTypeName === 'Intern' ? true : false,
            showMachAndMba: data[0].HireTypeID === 3 ? true : false,
            isMACH: data[0].IsMach === 1 ? true : false,
            isMBA: data[0].IsMba === 1 ? true : false,
            hireTypesOptions: hiretypeoptions,
            hireTypeID: data[0].HireTypeID,
        };
        setHireInfo(hireInfo);
    }

    async function getPersonalInfo(data: INewAuthForm[]) {
        const updatedPersonalInfo = {
            ...personalInfo,
            personalDetails:
                isNotNullOrUndefined(data[0].PersonalDetails)
                    ? data[0].PersonalDetails
                    : emptyPersonalDetails,
            workDestinationAddress: {
                City: data[0].WorkDestinationCity,
                Country: data[0].WorkDestinationCountry,
                StateProvince: data[0].WorkDestinationStateProvince,
            },
            homeDestinationAddress:
                isNotNullOrUndefined(data[0].DestinationAddress)
                ? data[0].DestinationAddress
                : emptyAddress,
            departureAddress:
                isNotNullOrUndefined(data[0].DepartureAddress)
                    ? {
                        ...data[0].DepartureAddress,
                        PostalCode: isNotNullOrUndefined(data[0].DepartureAddress.PostalCode)
                                ? (data[0].DepartureAddress.PostalCode).toString()
                                : '',
                        ReloID: isNotNullOrUndefined(data[0].DepartureAddress.ReloID)
                                ? (data[0].DepartureAddress.ReloID)
                                : 0
                    }
                    : emptyAddress,
            visaOptions: [
                { key: 'Yes', text: 'Yes' },
                { key: 'No', text: 'No' },
            ],
        };

        setPersonalInfo(updatedPersonalInfo);
    }

    async function getEmploymentInfo(data: INewAuthForm[]) {
        const jobLevelsOptionResponse = await reviewFormService.getJobLevelInformation();
        jobLevelsOptionResponse.splice(0, 0, { ID: '0', JobLevelName: 'Select One', IsRemoved: false });
        setJobLevelInfoResponse(jobLevelsOptionResponse);
        let jobLevel = jobLevelsOptionResponse;

        const employmentInfo = {
            businessGroup: data[0].BusinessGroup,
            costCenter: data[0].CostCenter,
            jobLevel:
                data[0].JobLevelID == null
                    ? jobLevel[0].JobLevelName
                    : jobLevelsOptionResponse.find((item) => {
                        return item.ID == data[0].JobLevelID;
                    })?.JobLevelName!,
            jobLevelID: data[0].JobLevelID == null ? jobLevel[0].ID : data[0].JobLevelID,
            hRStaffingManagerAlias: data[0].HRStaffingManagerAlias,
            hRRecruitingAssociate: userdata.Alias,
            hiringManagerAlias: data[0].HiringManagerAlias,
            estimatedStartDate: '',
            estimatedEndDate: '',
            hrStaffingGroup: data[0].HRStaffingGroupAlias,
            hRRecruiterAlias: data[0].HRRecruiterAlias,
            jobLevelOptions: [],
            businessGroupOptions: [],
            RushComments: data[0].RushComments,
            DestinationCompanyCode: data[0].DestinationCompanyCode,
            DepartureCompanyCode: data[0].DepartureCompanyCode,
            RequisitionID: data[0].RequisitionID,
        };
        setEmploymentInfo(employmentInfo);
    }

    async function getRelocationInfo(data: INewAuthForm[]) {
        const relocationPolicyResponse = await reviewFormService.getrelocationPolicy(parseInt(props.hireTypeOption));
        const newRelocationPolicyTypeOptions = relocationPolicyResponse.map(item => ({ key: item.RelocationPolicyID, text: item.RelocationPolicyName }));

        set_RelocationPolicyID(parseInt(data[0].RelocationPolicyID));
        set_RelocationpolicyTypeID(parseInt(data[0].RelocationpolicyTypeID));
        set_Comments(data[0].Comments!);
        set_rushComments(data[0].RushComments!);
        set_departureCompanyCode(data[0].DepartureCompanyCode!);
        set_destinationCompanyCode(data[0].DestinationCompanyCode!);
        set_requisitionID(data[0].RequisitionID!);
        setRelocationType('');
        set_FiscalYear(data[0].FiscalYear);
        set_PreHireTypeBenefitsMapping(data[0].PreHireTypeBenefitsMapping);
        setExceptionOptions([]);
        set_PassExceptionList([]);
        setRelocationPolicyOptions(newRelocationPolicyTypeOptions);
        setRelocationPolicyTypeOptions([]);
        setselectedException('');
        setExceptionListUpdatedFlag(0);
        setReloTypes([]);
        setShowLumpSumPanel(false);
        setShowBenefitsPanel(false);
        setShowPreHireTypePanel(false);
        set_AllOptionalBenefits([]);
        setPreHireTypeBenefits([]);
        set_SelectedPreHireTypeBenefits([]);
        set_CoreBenefits([]);
        set_CoreAllowance([]);
        setShowBenefitsPanel(false);
    }

    const dismissDeletePopUp = () => {
        setIsDeletePopUpOpen(false);
    };

    const dismissReviewFormModal = () => {
        props.setShowReviewFormModal();
        setIsHireInfoReadOnly(false);
    };

    const onDeleteClick = () => {
        setIsDeletePopUpOpen(true);
    };

    const onSubmitClick = async (e: any) => {
        e.preventDefault();
        const isSubmit = true;
        let formData: any = getReloFormToSaveOrSubmit(isSubmit);
        setSubmittedForm(formData);
    };

    React.useEffect(() => {
        if (submittedForm !== undefined) {
            if (!validateOnSubmit) {
                setValidateOnSubmit(true);
                setValidateOnReSubmit(false);
            } else {
                setValidateOnSubmit(false);
                setValidateOnReSubmit(true);
            }
        }
    }, [submittedForm]);

    React.useEffect(() => {
        if (validateOnSubmit || validateOnReSubmit) {
            if (submittedForm == undefined || submittedForm == null) {
                setApiError(true);
                setMessageText('Please Enter a Valid Data Before Submitting The Form');
            } else {
                if (submittedForm?.HireTypeID != 6 && submittedForm?.HireTypeID != 7) {
                    if (
                        submittedForm?.CandidateID == null ||
                        submittedForm?.CandidateID == undefined ||
                        submittedForm?.CandidateID == '' ||
                        submittedForm?.CandidateID == 0
                    ) {
                        if (
                            submittedForm?.EmployeeID == null ||
                            submittedForm?.EmployeeID == undefined ||
                            submittedForm?.EmployeeID.toString() == '' ||
                            submittedForm?.EmployeeID == 0
                        ) {
                            setApiError(true);
                            setMessageText(
                                'Please Enter a Valid Candidate ID or Employee ID or HireType Before Submitting The Form'
                            );
                        } else {
                            if (isFormValid) {
                                mainSubmit(submittedForm);
                            }
                        }
                    } else {
                        if (isFormValid) {
                            mainSubmit(submittedForm);
                        }
                    }
                } else {
                    if (isFormValid) {
                        mainSubmit(submittedForm);
                    }
                }
            }
        }
    }, [validateOnSubmit, validateOnReSubmit, isFormValid]);

    React.useEffect(() => {
        if (validateOnSubmit || validateOnReSubmit) {
            if (errorFields.length === 0) {
                setisFormValid(true);
            }else{
                if(validateOnSubmit){
                    setValidateOnSubmit(false);
                }else{
                    setValidateOnReSubmit(false);
                }
            }
        }
    }, [errorFields]);

    const mainSubmit = async (data: INewAuthForm) => {
        if (errorFields.length === 0) {
            let savedReloResponse = await saveRelocationFormData(true, data);

            let submitObj: ISendBack = {} as ISendBack;
            submitObj.status = 'Submitted';
            if(data.RelocationPolicyID=='7'){
                submitObj.status = 'BenivoSubmission';
            }
            else if (_formStatus == 'SENDBACK' || _formStatus == 'RESUBMITTED') {
                submitObj.status = 'Resubmitted';
            }
            submitObj.reloID = savedReloResponse.ReloID;
            submitObj.alias = userdata.Alias;
            submitObj.comments = _comments ? _comments : '';
            submitObj.personalDetails = savedReloResponse.PersonalDetails;
            submitObj.estimatedStartDate = new Date(savedReloResponse.EstimatedStartDate!);

            setIsLoading(true);
            await startNewReloRequestService
                .Submit(submitObj)
                .then((res) => {
                    setMessageText('Success! This record has been successfully submitted.');
                })
                .catch(() => {
                    setMessageText('Error! This record was not submitted, please try again.');
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const erroredFields = (errorFields: any) => {
        setErrorFields((preState) => {
            let mySet = new Set([...preState, ...errorFields]);
            return Array.from(mySet);
        });
    };

    const clearedFields = (clearField: any) => {
        let filteredFields: any[] = [];
        if (Array.isArray(clearField)) {
            filteredFields = errorFields;
            clearField.map((element) => {
                filteredFields = filteredFields.filter((item) => item !== element);
            });
        } else {
            filteredFields = errorFields.filter((item) => item !== clearField);
        }
        setErrorFields(filteredFields);
    };

    const titleId = useId('title');

    const handleErrorMessageBar = () => {
        setApiError(false);
        setMessageText('');
    };

    const handleSuccessMessageBar = () => {
        setApiSuccess(false);
        setMessageText('');
    };

    const updateLumSumNetCash = (value: any) => {
        set_LumpSumRelocation((prevState) => {
            let newState = {
                ...prevState,
                NetCash: isNaN(parseInt(value)) ? 0 : parseInt(value)
            }
            return newState
        });
        updateLumSumAccuralCost(value)
    }

    const updateLumSumAccuralCost = (value: any) => {
        let accrualCostCenter = 0;
        const netCashVal = value;
        const taxNetCashVal = 100.00 + _taxPctForLumpSum;
        accrualCostCenter = (netCashVal * taxNetCashVal) / 100;
        set_LumpSumRelocation((prevState) => {
            let newState = {
                ...prevState,
                AccrualCostCenter: isNaN(Number(accrualCostCenter.toFixed(2))) ? 0 : Number(accrualCostCenter.toFixed(2))

            }
            return newState
        });
    }

    return (
        <>
            <Modal
                titleAriaId={titleId}
                isOpen={isModalOpen}
                isBlocking={true}
                containerClassName={contentStyles.container}
                layerProps={{ eventBubblingEnabled: true }} // allows drag and drop feature
            >
                {!isLoaded ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={cancelIcon}
                            ariaLabel="Close popup modal"
                            role='button'
                            onClick={dismissReviewFormModal}
                        />
                        <h1>New Relocation Form</h1>
                        {isDeletePopUpOpen && (
                            <DeleteFormPopUp
                                dismiss={dismissDeletePopUp} 
                                recorID={hireInfo.reloID.toString()} 
                            />
                        )}

                        {isLoading && (
                            <Stack>
                                <div>
                                    <Spinner
                                        label="Loading, please wait..."
                                        size={SpinnerSize.large}
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            height: '100%',
                                            width: '100%',
                                            zIndex: 9999999,
                                            backgroundColor: 'rgb(255, 255, 255, 255)',
                                        }}
                                    />
                                </div>
                            </Stack>
                        )}
                        <Stack
                            horizontal={true}
                            className="statusSection"
                            style={{ borderTop: '3px solid #dcdcdc' }}
                            horizontalAlign="end"
                        >
                            <Stack.Item>
                                <Separator alignContent="end">{_formStatus}</Separator>
                            </Stack.Item>
                        </Stack>

                        {apiSuccess && (
                            <MessageBar
                                messageBarType={MessageBarType.success}
                                isMultiline={false}
                                onDismiss={handleSuccessMessageBar}
                                dismissButtonAriaLabel="Close"
                            >
                                {messageText}
                            </MessageBar>
                        )}
                        {apiSuccessRemovedFile0 && (
                        <MessageBar
                            messageBarType={MessageBarType.success}
                            isMultiline={false}
                            dismissButtonAriaLabel="Close"
                            role={'alert'}
                        >
                            {messageText}
                        </MessageBar>
                        )}
                        {apiSuccessRemovedFile1 && (
                            <MessageBar
                                messageBarType={MessageBarType.success}
                                isMultiline={false}
                                dismissButtonAriaLabel="Close"
                                role={'alert'}
                            >
                                {messageText}
                            </MessageBar>
                        )}
                        {apiSuccessRemovedFile2 && (
                            <MessageBar
                                messageBarType={MessageBarType.success}
                                isMultiline={false}
                                dismissButtonAriaLabel="Close"
                                role={'alert'}
                            >
                                {messageText}
                            </MessageBar>
                        )}
                        {apiSuccessRemovedFile3 && (
                            <MessageBar
                                messageBarType={MessageBarType.success}
                                isMultiline={false}
                                dismissButtonAriaLabel="Close"
                                role={'alert'}
                            >
                                {messageText}
                            </MessageBar>
                        )}
                        {apiSuccessRemovedFile4 && (
                            <MessageBar
                                messageBarType={MessageBarType.success}
                                isMultiline={false}
                                dismissButtonAriaLabel="Close"
                                role={'alert'}
                            >
                                {messageText}
                            </MessageBar>
                        )}
                        {apiError && (
                            <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={false}
                                onDismiss={handleErrorMessageBar}
                                dismissButtonAriaLabel="Close"
                            >
                                {messageText}
                            </MessageBar>
                        )}

                        <Stack className={contentStyles.body}>
                            <HireInformation
                                hireInfo={hireInfo}
                                isFormEditable={isHireInfoReadOnly}
                                internshipTypeID={reloFormDataResponse[0].InternshipTypeID}
                                updateHireInfo={updateHireInfo}
                                onInternshipTypeChange={onInternshipTypeChange}
                                status={isSaved}
                            />
                            <PersonalInformation
                                personalInfo={personalInfo}
                                updatePersonalDetails={updatePersonalDetails}
                                updateDepartureAddress={updateDepartureAddress}
                                updateDestinationAddress={updateDestinationAddress}
                                homeDestinationAddressDetails={homeDestinationAddressDetails}
                                bulkHomeDestinationAddressUpdate={bulkHomeDestinationAddressUpdate}
                                validateOnSubmit={validateOnSubmit}
                                validateOnReSubmit={validateOnReSubmit}
                                erroredFields={erroredFields}
                                clearedFields={clearedFields}
                                errorFieldsState={errorFields}
                            />
                            <EmploymentInformation
                                employmentInfo={employmentInfo}
                                jobLevelData={jobLevelInfoResponse}
                                updateEmploymentInfo={updateEmploymentInfo}
                                onJobLevelChange={changeJobLevel}
                                updateJobLevelInfo={updateJobLevelInfo}
                                showHrStaffingManagerAliastxtBox={showHrStaffingManagerAliasTextBox}
                                HrStaffingNameLabel={hrStaffingNameLabel}
                                HrHiringManagerLabel={hiringManagerLabel}
                                InternshipID={reloFormDataResponse[0].InternshipTypeID}
                                HireTypeID={parseInt(props.hireTypeOption)}
                                validateOnSubmit={validateOnSubmit}
                                validateOnReSubmit={validateOnReSubmit}
                                erroredFields={erroredFields}
                                clearedFields={clearedFields}
                                errorFieldsState={errorFields}
                                EstimatedStartDateLabelText={EstimatedStartDateLabel}
                                RushComments={_rushComments}
                                relocationPolicyID={_relocationPolicyID}
                                relocationpolicyTypeID={_relocationpolicyTypeID}
                                relocationType={relocationType}
                                PolicyReloTypeChanged={_policyReloTypeChanged}
                            />
                            <RelocationInformation
                                reloInfo={updatedReloForm}
                                hireTypeOption={parseInt(props.hireTypeOption)}
                                employmentInfo={employmentInfo}
                                newAuthFormData={reloFormDataResponse}
                                changeReloType={changeReloType}
                                handleExceptionUnitValueUpdate={handleExceptionUnitValueUpdate}
                                handleRemoveException={handleRemoveException}
                                handleAddException={handleAddException}
                                updateRelocationInfo={updateRelocationInfo}
                                onRelocationPolicyChange={LoadRelocationType}
                                showBenefitsPanel={showBenefitsPanel}
                                showOptionalException={showOptionalException}
                                relocationPolicyID={_relocationPolicyID}
                                relocationpolicyTypeID={_relocationpolicyTypeID}
                                relocationType={relocationType}
                                comments={_comments}
                                fiscalYear={_fiscalYear}
                                preHireTypeBenefitsMapping={_preHireTypeBenefitsMapping}
                                relocationPolicyOptions={relocationPolicyOptions}
                                relocationPolicyTypeOptions={relocationPolicyTypeOptions}
                                exceptionOptions={exceptionOptions}
                                passExceptionList={_passExceptionList}
                                exceptionListUpdatedFlag={exceptionListUpdatedFlag}
                                showLumpSumPanel={showLumpSumPanel}
                                showPreHireTypePanel={showPreHireTypePanel}
                                allOptionalBenefits={_allOptionalBenefits}
                                preHireTypeBenefits={preHireTypeBenefits}
                                selectedPreHireTypeBenefits={_selectedPreHireTypeBenefits}
                                coreBenefits={_coreBenefits}
                                coreAllowance={_coreAllowance}
                                updateRelocationComments={updateRelocationComments}
                                updateSelectedException={updateSelectedException}
                                handleOptionalBenefitUnitUpdate={handleOptionalBenefitUnitUpdate}
                                updateLumSumNetCash={updateLumSumNetCash}
                                updateRelocationCashAmt={updateRelocationCashAmt}
                                updateLumSumAccuralCost={updateLumSumAccuralCost}
                                updatePreHireSelectedBenefits={updatePreHireSelectedBenefits}
                                validateOnSubmit={validateOnSubmit}
                                validateOnReSubmit={validateOnReSubmit}
                                erroredFields={erroredFields}
                                clearedFields={clearedFields}
                                errorFieldsState={errorFields}
                                exceptionsResponse={exceptionsResponse}
                                relocationCashAmount={_relocationCashAmount}
                                lumpSumRelocation={_lumpSumRelocation}
                                taxpactforlumpsum={_taxPctForLumpSum}
                                jobLevelMsg={jobLevelMessage}
                            />
                            <RelocationSummaryAttachment
                                uploadaddfile={uploadaddfile}
                                uploadfile={uploadfile}
                                RemoveSelectedReloFile={RemoveSelectedReloFile}
                                RemoveAddSelectedReloFile={RemoveAddSelectedReloFile}
                                updateAdditionalDescriptionValue={updateAdditionalDescriptionValue}
                                validateOnSubmit={validateOnSubmit}
                                validateOnReSubmit={validateOnReSubmit}
                                erroredFields={erroredFields}
                                clearedFields={clearedFields}
                                reloFile={_requiredReloSummary}
                                hireTypeID={parseInt(props.hireTypeOption)}
                                addtionalReloFile0={_additionalReloSummary0}
                                addtionalReloFile1={_additionalReloSummary1}
                                addtionalReloFile2={_additionalReloSummary2}
                                addtionalReloFile3={_additionalReloSummary3}
                                addtionalReloFile4={_additionalReloSummary4}
                                GUID0={_attachmentGUID0}
                                GUID1={_attachmentGUID1}
                                GUID2={_attachmentGUID2}
                                GUID3={_attachmentGUID3}
                                GUID4={_attachmentGUID4}
                                reloID={reloFormDataResponse[0].ReloID}
                                AttachRelocationSummaryText={AttachRelocationSummaryText}
                                hidemessageBox={hidemessageBox}
                            />
                            {showButtons && (
                                <Stack
                                    className="SubmitButtons"
                                    horizontal
                                    horizontalAlign="end"
                                    tokens={{ childrenGap: 20 }}
                                    styles={stackStyles}
                                >
                                    {showSaveButton && (
                                        <PrimaryButton
                                            text="Save"
                                            onClick={() => {
                                                OnSaveClick(false);
                                            }}
                                            allowDisabledFocus
                                            disabled={false}
                                            style={buttonStyles}
                                        />
                                    )}
                                    {showSubmitButton && (
                                        <PrimaryButton
                                            text="Submit"
                                            onClick={(e) => onSubmitClick(e)}
                                            allowDisabledFocus
                                            disabled={false}
                                            style={buttonStyles}
                                        />
                                    )}
                                    {_formStatus == 'SAVED' && (
                                        <PrimaryButton
                                            text="Delete e-form"
                                            onClick={onDeleteClick}
                                            allowDisabledFocus
                                            disabled={false}
                                            style={buttonStyles}
                                        />
                                    )}
                                </Stack>
                            )}
                            {StackDisclaimer()}
                        </Stack>
                    </>
                )}
            </Modal>
        </>
    );

    function updateHireInfo(fieldName: string, value: any) {
        let temp = JSON.parse(
            JSON.stringify({
                ...hireInfo,
                [fieldName]: value,
            })
        );
        setHireInfo(temp);
    }

    function updatePersonalDetails(fieldName: string, value: any) {
        let temp = JSON.parse(
            JSON.stringify({
                ...personalInfo,
                personalDetails: {
                    ...personalInfo.personalDetails,
                    [fieldName]: value,
                },
            })
        );
        setPersonalInfo(temp);
    }

    function homeDestinationAddressDetails(fieldName: string, value: any) {
        let temp = JSON.parse(
            JSON.stringify({
                ...personalInfo,
                homeDestinationAddress: {
                    ...personalInfo.homeDestinationAddress,
                    [fieldName]: value,
                },
            })
        );
        setPersonalInfo(temp);
    }

    function bulkHomeDestinationAddressUpdate(homeAddressObject: any) {
        let temp = JSON.parse(
            JSON.stringify({
                ...personalInfo,
                homeDestinationAddress: homeAddressObject,
            })
        );
        setPersonalInfo(temp);
    }

    function updateDepartureAddress(fieldName: string, value: any) {
        let temp = JSON.parse(
            JSON.stringify({
                ...personalInfo,
                departureAddress: {
                    ...personalInfo.departureAddress,
                    [fieldName]: value,
                },
            })
        );
        setPersonalInfo(temp);
    }

    function updateDestinationAddress(fieldName: string, value: any) {
        let temp = JSON.parse(
            JSON.stringify({
                ...personalInfo,
                workDestinationAddress: {
                    ...personalInfo.workDestinationAddress,
                    [fieldName]: value,
                },
                homeDestinationAddress: {
                    ...personalInfo.homeDestinationAddress,
                    [fieldName]: value,
                },
            })
        );
        setPersonalInfo(temp);
    }

    function updateEmploymentInfo(fieldName: string, value: any) {
        let temp = JSON.parse(
            JSON.stringify({
                ...employmentInfo,
                [fieldName]: value,
            })
        );
        setEmploymentInfo(temp);
    }

    function updateJobLevelInfo(jobLevelObj: any) {
        let temp = JSON.parse(
            JSON.stringify({
                ...employmentInfo,
                jobLevel: jobLevelObj.jobLevel,
                jobLevelID: jobLevelObj.jobLevelID,
            })
        );
        setEmploymentInfo(temp);
    }


    function updateRelocationInfo(fieldName: string, value: any) {
        let temp = JSON.parse(
            JSON.stringify({
                ...updatedReloForm,
                [fieldName]: value,
            })
        );
        setUpdatedReloForm(temp);
    }

    function updateRelocationComments(value: any) {
        set_Comments(value);
    }

    function updateSelectedException(value: any) {
        setselectedException(value);
    }

    function updatePreHireSelectedBenefits(selectedPreHireBenefits: PreHireTypeBenefits[]) {
        let selectedBenefits: PreHireTypeBenefits[] = [];
        selectedPreHireBenefits.forEach((item) => {
            selectedBenefits.push(item);
        });
        set_SelectedPreHireTypeBenefits(selectedBenefits);
    }

    function updateRelocationCashAmt(value: any) {
        set_RelocationCashAmount(value);
    }

    function isNotNullOrUndefined(obj: any) {
        if (obj !== undefined && obj !== null) return true;
        else return false;
    }

    function changeJobLevel() {
        set_RelocationpolicyTypeID(0);
        set_RelocationPolicyID(0);
        LoadRelocationType({} as IDropdownOption);
        setShowBenefitsPanel(false);
        setShowLumpSumPanel(false);
        setselectedRelocationCashAmount(0);
        set_LumpSumRelocation({} as LumpSumRelocation);
        if (
            isNotNullOrUndefined(_relocationpolicyTypeID) &&
            isNotNullOrUndefined(_relocationPolicyID) &&
            hireInfo.hireTypeID != 0
        ) {
            loadLumpSumAmount(_relocationpolicyTypeID!, hireInfo.hireTypeID, _relocationPolicyID!, _fiscalYear!);
        }        
    }

    async function changeReloType(option: IDropdownOption) {
        set_RelocationpolicyTypeID(option.key as number);
        let reloTypeId = option.key as number;
        let fiscalYear;
        set_PassExceptionList([]);
        let date = new Date();
        if (
            employmentInfo.estimatedStartDate !== null &&
            employmentInfo.estimatedStartDate !== undefined &&
            employmentInfo.estimatedStartDate !== ''
        ) {
            date = new Date(employmentInfo.estimatedStartDate);
        }
        if (date.getMonth() > 5) {
            fiscalYear = date.getFullYear() + 1;
        } else {
            fiscalYear = date.getFullYear();
        }
        if (reloTypeId != 0 && reloTypeId !== null && reloTypeId !== undefined) {
            let selectedReloType: RelocationType = reloTypes.filter((item) => {
                return item.RelocationTypeID === reloTypeId;
            })[0];
            if (isNotNullOrUndefined(selectedReloType) && selectedReloType.HireTypeID == 7) {
                await getPreHireTypeBenefits();
                setShowLumpSumPanel(false);
                setShowBenefitsPanel(false);
                setShowPreHireTypePanel(true);
                set_FiscalYear(fiscalYear);
            } else {
                if (isNotNullOrUndefined(selectedReloType) && selectedReloType.ReloPolicyType != null) {
                    if (selectedReloType.ReloPolicyType == 'LumSum') {
                        setShowLumpSumPanel(true);
                        set_FiscalYear(fiscalYear);
                        setRelocationType(selectedReloType.RelocationTypeName);
                        setShowPreHireTypePanel(false);
                        if(selectedReloType.RelocationTypeName.includes("Plus")){
                            setShowBenefitsPanel(true);
                            setShowOptionalException(false);
                            await getCoreBenefits(reloTypeId);
                        }else{
                            setShowBenefitsPanel(false);
                            set_CoreBenefits([]);
                        }
                        set_AllOptionalBenefits(
                            _allOptionalBenefits.map((option) => {
                                return { ...option, isSelected: false };
                            })
                        );
                        await loadLumpSumAmount(reloTypeId, hireInfo.hireTypeID, _relocationPolicyID!, fiscalYear);
                    } else {
                        setShowLumpSumPanel(false);
                        setShowBenefitsPanel(true);
                        setShowOptionalException(true);
                        set_FiscalYear(fiscalYear);
                        set_LumpSumRelocation({} as LumpSumRelocation);
                        await getCoreBenefits(reloTypeId);
                        await getExceptions(parseInt(selectedReloType.RelocationTypeID));
                        if (isNotNullOrUndefined(selectedReloType)) {
                            await getAllOptionalBenefits(
                                selectedReloType.ReloPolicyType,
                                parseInt(selectedReloType.RelocationTypeID)
                            );
                        }
                    }
                }
            }
            if (reloTypeId == 36) {
                setShowLumpSumPanel(false);
                setShowBenefitsPanel(false);
                set_RelocationCashAmount(0);
                await getExceptions(reloTypeId);
            }
            else if(reloTypeId == 54 || reloTypeId == 55){
                setShowOptionalException(false);
            }
        } else {
            setShowLumpSumPanel(false);
            setShowBenefitsPanel(false);
        }
        set_policyReloTypeChanged(true);
    }

    async function getPreHireTypeBenefits() {
        let preHireTypeBenefits: any[] = [];
        let selectedPreHireTypeBenefits: any[] = [];
        preHireTypeBenefits = await startNewReloRequestService.GetPreHireTypeBenefits();
        preHireTypeBenefits.forEach((item: { isSelected: boolean; ID: number }) => {
            if (updatedReloForm?.preHireTypeBenefitsMapping && updatedReloForm?.preHireTypeBenefitsMapping.length > 0) {
                _preHireTypeBenefitsMapping.forEach((option) => {
                    if (item.ID == option.SelectedPreHireTypeBenefitID) {
                        item.isSelected = true;
                        selectedPreHireTypeBenefits.push(option);
                    }
                });
            } else {
                item.isSelected = false;
            }
            setPreHireTypeBenefits(preHireTypeBenefits);
            set_SelectedPreHireTypeBenefits(selectedPreHireTypeBenefits);
        });
    }

    async function loadLumpSumAmount(ReloTypeID: number, HireTypeID: number, RelocationPolicyID: number, fiscalYear: number) {
        setJobLevelMessage(false);
        let taxPct = {TaxRateForLumpSum:0};
        let lumpSumPolicyTypes = await startNewReloRequestService.GetLumpSumRelocationTypes(fiscalYear);
        let selectedJobLevel = employmentInfo.jobLevelOptions.filter(
            (item) => item.key == employmentInfo.jobLevelID
        )[0];
        let lumpSumJobLevel = '';
        if (isNotNullOrUndefined(selectedJobLevel)) {
            lumpSumJobLevel = 'N/A';
            if (selectedJobLevel.key == '1') {
                lumpSumJobLevel = 'Below L60';
            } else if (
                selectedJobLevel.key == '2' ||
                selectedJobLevel.key == '3' ||
                selectedJobLevel.key == '4' ||
                selectedJobLevel.key == '5' ||
                selectedJobLevel.key == '11'
            ) {
                lumpSumJobLevel = 'L60-L64';
            } else if (selectedJobLevel.key == '6' || selectedJobLevel.key == '7' || selectedJobLevel.key == '8') {
                lumpSumJobLevel = 'L65-L67';
            } else if (selectedJobLevel.key == '9' || selectedJobLevel.key == '10') {
                lumpSumJobLevel = 'Above L68';
            }
        }
        if (ReloTypeID == 28 || ReloTypeID == 24 || ReloTypeID == 25 || ReloTypeID == 52 || ReloTypeID == 59) {
            lumpSumPolicyTypes = lumpSumPolicyTypes.filter(
                (item: { RelocationTypeID: number }) => item.RelocationTypeID == ReloTypeID
            );
            if(HireTypeID == 4){
                taxPct = lumpSumPolicyTypes.filter(
                    (item) => item.RelocationTypeID == ReloTypeID
                    &&  item.RelocationPolicyID == RelocationPolicyID
                )[0];
                set_TaxPctForLumpSum(taxPct.TaxRateForLumpSum || 0)
            }
        } else {
            lumpSumPolicyTypes = lumpSumPolicyTypes.filter(
                (item: { RelocationTypeID: number; JobLevel: string; HireTypeID: number }) => {
                    return (
                        item.RelocationTypeID == ReloTypeID &&
                        item.JobLevel == lumpSumJobLevel &&
                        item.HireTypeID == HireTypeID
                    );
                }
            );
            if (HireTypeID != 4 && selectedJobLevel != undefined) {
                let tax = taxByJobLevelsResponse.filter((item) => {
                    return item.JobLevelId == selectedJobLevel.key && item.Fiscal_Year == _fiscalYear;
                })[0];
                set_TaxPctForLumpSum(tax?.TaxRate || 0)
            } else if (lumpSumPolicyTypes && lumpSumPolicyTypes.length > 0) {
                set_TaxPctForLumpSum(lumpSumPolicyTypes[0].TaxRateForLumpSum)
            }
        }
        let savedNetcash = 0;
        let savedAccCostCenter = 0;
        if (isNotNullOrUndefined(lumpSumPolicyTypes) && lumpSumPolicyTypes.length > 0) {
            if (
                Object.keys(_lumpSumRelocation).length > 0 &&
                (_formStatus?.toLowerCase() == 'saved' ||
                    _formStatus?.toLowerCase() == 'ready to initiate' ||
                    _formStatus?.toLowerCase() == 'resubmitted')
            ) {
                if (!isSavedAlready) {
                    setIsSavedAlready(true);
                    savedNetcash = _lumpSumRelocation.NetCash;
                    savedAccCostCenter = parseFloat(
                        ((_lumpSumRelocation.NetCash * _taxPctForLumpSum) / 100 + _lumpSumRelocation.NetCash).toFixed(2)
                    );
                }
            }
            else {
                let lumpSumRelo: LumpSumRelocation = {
                    ID: 0,
                    RelocationPolicyTypeID: ReloTypeID!,
                    ReloID: hireInfo.reloID,
                    NetCash:
                        savedNetcash == 0
                            ? parseFloat(lumpSumPolicyTypes[0].NetCash.replace(/$/g, '').replace(/,/g, ''))
                            : savedNetcash,
                    AccrualCostCenter:
                        savedAccCostCenter == 0
                            ? parseFloat(
                                (
                                    (parseFloat(lumpSumPolicyTypes[0].NetCash.replace(/$/g, '').replace(/,/g, '')) *
                                        (_taxPctForLumpSum||taxPct.TaxRateForLumpSum)) /
                                    100 +
                                    parseFloat(lumpSumPolicyTypes[0].NetCash.replace(/$/, '').replace(/,/, ''))
                                ).toFixed(2)
                            )
                            : savedAccCostCenter,
                    CreatedBy: userdata.Alias,
                    CreatedOn: new Date().toString(),
                    IsRemoved: false,
                    TotalPackageValue: 0,
                    FileFee: 0,
                    TaxRate: 0,
                };
                set_LumpSumRelocation(lumpSumRelo);
            }
        } else {
            setJobLevelMessage(true);
        }

    }

    async function getCoreBenefits(reloTypeId: number) {
        let fiscal_Year = _fiscalYear;
        if (fiscal_Year === undefined || fiscal_Year === 0) {
            fiscal_Year = new Date().getFullYear();
        }
        let coreBenefits: any[] = [];

        let resp = await reviewFormService.getCoreBenefits(reloTypeId, fiscal_Year);
        let coreAllowance: any[] = [];
        let coreTaxRate = '';
        let selectedJobLevel = employmentInfo.jobLevelOptions.filter(
            (item) => item.key == employmentInfo.jobLevelID
        )[0];
        if (hireInfo.hireTypeID != 4 && selectedJobLevel != undefined) {
            let tax = taxByJobLevelsResponse.filter((item) => {
                return item.JobLevelId == selectedJobLevel.key && item.Fiscal_Year == fiscal_Year;
            })[0];
            coreTaxRate = tax?.TaxRate?.toString() || '';
        }
        let filterResult = resp.filter((e: any) => {
            if (e.CoreBenifitID == 12 && hireInfo.hireTypeID != 4) {
                e.Amount = 'Included @ ' + coreTaxRate + '%';
            }
            if (e.CoreBeniftName.trim() == 'Relocation Cash Allowance') {
                if (
                    e.RelocationPolicyTypeID === 19 ||
                    e.RelocationPolicyTypeID === 20 ||
                    e.RelocationPolicyTypeID === 21 ||
                    e.RelocationPolicyTypeID === 37
                ) {
                    if (e.CoreBenifitID == 3) {
                        coreAllowance.push({
                            CoreBenifitID: 0,
                            CashAmount: e.Amount,
                            IsLessThan: parseFloat(e.Amount) <= 10000 ? true : false,
                            CoreBeniftName: 'Relocation Cash Allowance',
                            IsDisabled: true,
                        });
                        e.Amount = '$' + e.Amount.toString();
                    }
                    return e;
                } else {
                    coreAllowance.push({
                        CoreBenifitID: 0,
                        CashAmount: e.Amount,
                        IsLessThan: parseFloat(e.Amount) <= 10000 ? true : false,
                        CoreBeniftName: 'Relocation Cash Allowance',
                        IsDisabled: false,
                    });
                }
                let reloCashAmt = 0;
                if (
                    (e.RelocationPolicyTypeID === 39 ||
                        e.RelocationPolicyTypeID === 12 ||
                        e.RelocationPolicyTypeID === 13 ||
                        e.RelocationPolicyTypeID === 18) &&
                    e.CoreBenifitID == 3
                ) {
                    reloCashAmt = e.Amount;
                } else {
                    reloCashAmt = hireInfo.reloID != 0 ? selectedRelocationCashAmount : 0;
                }
                set_RelocationCashAmount(reloCashAmt);
            }
            return e.CoreBeniftName.trim() != 'Relocation Cash Allowance';
        });
        if (coreAllowance.length == 0) {
            set_RelocationCashAmount(0);
        }
        /*
        As lump sum have multiple repeated core benefits for different levels
        for that we only grab the first one.
        */
        if(reloTypeId == 57 || reloTypeId == 59){
            filterResult = filterResult.slice(0,1);
        }
        coreBenefits = filterResult;
        set_CoreBenefits(coreBenefits);
        set_CoreAllowance(coreAllowance);
        set_FiscalYear(fiscal_Year);
    }

    async function getExceptions(relocationpolicyTypeID: number) {
        const exceptionsResponse = (await reviewFormService.getExceptions(relocationpolicyTypeID))
        .map((item) => ({ ...item, Unit: '', Amount: '' }))
            .filter((item) => item.OptionalBenifitID === 0);

        const exceptionsDropDownOptions: IDropdownOption[] = exceptionsResponse.map(item => ({ key: item.ExceptionID, text: item.ExceptionName }))

        setExceptionsResponse(exceptionsResponse);
        setExceptionOptions(exceptionsDropDownOptions);
    }

    async function getAllOptionalBenefits(reloType: string, relocationPolicyTypeID: number) {
        await getOptionalBenefits(relocationPolicyTypeID, reloType);
        await getExceptions(relocationPolicyTypeID);
    }

    async function getOptionalBenefits(RelocationPolicyTypeID: number, ReloPolicyType: string) {
        const allOptionalBenefits = (await startNewReloRequestService.GetRelocationOptionalBenefits(RelocationPolicyTypeID))
        .filter((item, index, self) => self.findIndex((item2) => item.OptionalBenifitID === item2.OptionalBenifitID) === index);

        const allOptionalBenefitsMaxAmounts = (await startNewReloRequestService
            .GetAllBenefits(ReloPolicyType))
            .map(item => ({ OptionalBenifitID: item.OptionalBenifitID, MaxAmount: item.MaxAmount }));

        const options = allOptionalBenefits.map((column: any, index: any) => {
            column.disableStatus = true;
            column.UnitMaxValue = (column.Unit?.replace(/[^0-9]/gm, '').includes("2")) ? '2' : '1000';
            column.Unit = (isNaN(Number(column.Unit))) ? '' : column.Unit;
            column.UnitType = (column?.UnitType === "Free Field") ? column.UnitType.replace(/\s/g, '') : column.UnitType;
            const findRecord = allOptionalBenefitsMaxAmounts.find((optionalBenefitsItem) => optionalBenefitsItem.OptionalBenifitID === column.OptionalBenifitID);
            column.MaxAmount = (findRecord?.MaxAmount) ? findRecord.MaxAmount : '1500';
            return column;
        });

        set_AllOptionalBenefits(options);
    }

    function handleExceptionUnitValueUpdate(index: number, unitType: string, unitValue: string) {
        let obj = _passExceptionList;
        obj[index] = { ...obj[index], [unitType]: (unitValue !== '' && !isNaN(Number(unitValue)) && 0 <= Number(unitValue)) ? (Number(unitValue)).toString() : '' };

        set_PassExceptionList(obj);
        setExceptionListUpdatedFlag(exceptionListUpdatedFlag! + 1);
    }

    function handleOptionalBenefitUnitUpdate(benefit: OptionalBenefitsType[]) {
        let updatedBenefits = _allOptionalBenefits.map(function (x) {
            let result = benefit.filter(
                (a1) => a1.OptionalBenifitID === x.OptionalBenifitID && a1.disableStatus == false
            );
            if (result.length > 0) {
                if (x?.UnitType === "Unit") {
                    x.Unit = result[0].Unit;
                } else if (x?.UnitType === "FreeField") {
                    x.Amount = result[0].Amount;
                }
                x.disableStatus = false;
            } else {
                x.disableStatus = true;
            }
            return x;
        });
        set_AllOptionalBenefits(updatedBenefits);
    }

    function handleRemoveException(index: number) {
        const allExceptions = _passExceptionList.slice(0, index).concat(_passExceptionList.slice(index + 1));
        let exNames = exceptionNames;
        exceptionNames = exNames.slice(0, index).concat(exNames.slice(index + 1));
        set_PassExceptionList(allExceptions);
        setExceptionListUpdatedFlag(exceptionListUpdatedFlag! + 1);
    }

    function handleAddException() {
        _passExceptionList.forEach((element) => {
            if (exceptionNames.indexOf(element.ExceptionName) == -1) {
                exceptionNames.push(element.ExceptionName);
            }
        });

        if (exceptionNames.indexOf(selectedException) == -1 && selectedException !== '') {
            exceptionsResponse.find((elem) => {
                if (elem.ExceptionName == selectedException) {
                    exceptionNames.push(selectedException);
                    let obj1 = _passExceptionList;

                    let obj2: ReloException = {
                        ReloID: props.newAuthFormData[0].ReloID,
                        ExceptionName: selectedException,
                        ExceptionID: elem.ExceptionID,
                        Unit: elem.Unit,
                        IsDeleted: elem.IsAvailable,
                        CustFileID: 0,
                        Amount: elem.Amount,
                        TotalAmount: elem.Actual_Amount,
                        IsPostAccrual: false,
                        UnitType: elem.UnitType,
                        Remove: '',
                    };

                    obj1.push(obj2);
                    setExceptionListUpdatedFlag(exceptionListUpdatedFlag! + 1);
                    set_PassExceptionList(obj1);
                }
            });
        } else {
            if (selectedException == '') {
                alert('Select Exception');
            } else {
                alert('This exeption alredy exists...');
            }
        }
    }

    function getAttachmentsToSaveOrSubmit() {
        let emptyReloSummary = {
            ReloID: hireInfo.reloID,
            CreatedBy: userdata.Alias,
            CreatedDate: new Date(),
            AttachReloSummaryID: 0,
            AddtionalDescription: '',
            Filename: null,
            IsRemoved: true,
        };

        return {
            AttachReloSummary:
            Object.keys(_requiredReloSummary).length > 0
                    ? _requiredReloSummary
                    : {
                        ...emptyReloSummary,
                        IsAdditional: 10,
                    },
            AdditonalReloSummary0:
                Object.keys(_additionalReloSummary0).length > 0
                    ? _additionalReloSummary0
                    : { ...emptyReloSummary,
                        IsAdditional: 0,
                    },
            AdditonalReloSummary1:
                Object.keys(_additionalReloSummary1).length > 0
                    ? _additionalReloSummary1
                    : {
                        ...emptyReloSummary,
                        IsAdditional: 1,
                    },
            AdditonalReloSummary2:
                Object.keys(_additionalReloSummary2).length > 0
                    ? _additionalReloSummary2
                    : {
                        ...emptyReloSummary,
                        IsAdditional: 2,
                    },
            AdditonalReloSummary3:
                Object.keys(_additionalReloSummary3).length > 0
                    ? _additionalReloSummary3
                    : {
                        ...emptyReloSummary,
                        IsAdditional: 3,
                    },
            AdditonalReloSummary4:
                Object.keys(_additionalReloSummary4).length > 0
                    ? _additionalReloSummary4
                    : {
                        ...emptyReloSummary,
                        IsAdditional: 4,
                    },
            AttachmentGUID: _attachmentGUID !== '' || null ? _attachmentGUID : null,
            AttachmentGUID0: _attachmentGUID0 !== '' || null ? _attachmentGUID0 : null,
            AttachmentGUID1: _attachmentGUID1 !== '' || null ? _attachmentGUID1 : null,
            AttachmentGUID2: _attachmentGUID2 !== '' || null ? _attachmentGUID2 : null,
            AttachmentGUID3: _attachmentGUID3 !== '' || null ? _attachmentGUID3 : null,
            AttachmentGUID4: _attachmentGUID4 !== '' || null ? _attachmentGUID4 : null,
        };
    }
    function getEmploymentInfoToSaveOrSubmit() {
        return {
            HRStaffinggroup: employmentInfo.hrStaffingGroup != '' ? employmentInfo.hrStaffingGroup : '',
            HRStaffingManagerAlias: employmentInfo.hRStaffingManagerAlias,
            HRRecruitingAssociate: employmentInfo.hRRecruitingAssociate,            
            HiringManagerAlias: employmentInfo.hiringManagerAlias,
            HRStaffingGroupAlias: employmentInfo.hrStaffingGroup,
            HRRecruiterAlias: employmentInfo.hRRecruiterAlias,            
            CostCenter: employmentInfo.costCenter,
            EstimatedEndDate: employmentInfo.estimatedEndDate
                ? moment(employmentInfo.estimatedEndDate).format('YYYY-MM-DD')
                : null,
            EstimatedStartDate: employmentInfo.estimatedStartDate
                ? moment(employmentInfo.estimatedStartDate).format('YYYY-MM-DD')
                : null,
            BusinessGroup: employmentInfo.businessGroup,
            JobLevelID: employmentInfo.jobLevelID,
            RushComments: employmentInfo.RushComments,
            DestinationCompanyCode: employmentInfo.DestinationCompanyCode != null ? employmentInfo.DestinationCompanyCode : 0,
            DepartureCompanyCode: employmentInfo.DepartureCompanyCode != null ? employmentInfo.DepartureCompanyCode : 0,
            RequisitionID: employmentInfo.RequisitionID != null ? employmentInfo.RequisitionID : 0
        };
    }
    
    function getPersonalInforToSaveOrSubmit() {
        return {
            DepartureAddress: {
                ...personalInfo.departureAddress,
                Apartment:
                    personalInfo.departureAddress.Apartment !== undefined
                        ? personalInfo.departureAddress.Apartment
                        : null,
                PostalCode: parseInt(personalInfo.departureAddress.PostalCode),
            },
            DestinationAddress: {
                ...personalInfo.homeDestinationAddress,
                ReloID: hireInfo.reloID,
            },
            WorkDestinationCountry: personalInfo.workDestinationAddress.Country,
            WorkDestinationCity: personalInfo.workDestinationAddress.City,
            WorkDestinationStateProvince: personalInfo.workDestinationAddress.StateProvince,
        };
    }

    function getHireInfoToSaveOrSubmit() {
        return {
            ReloID: hireInfo.reloID ? hireInfo.reloID : 0,
            CandidateID: hireInfo.candidateID ? hireInfo.candidateID : null,
            EmployeeID: (hireInfo.employeeID !== null && hireInfo.employeeID !== undefined && hireInfo.employeeID !== '') ? hireInfo.employeeID : '0',
            IsMach: hireInfo.isMACH ? 1 : 0,
            IsMba: hireInfo.isMBA ? 1 : 0,
            HireTypeID: hireInfo.hireTypeID
        }
    }
    
    function getSelectedExceptions() {
        let selectedGridExceptions: ExceptionMapping[] = [];
        if (_passExceptionList.length > 0 && isNotNullOrUndefined(_passExceptionList)) {
            _passExceptionList.forEach((item) => {
                selectedGridExceptions.push(item);
            });
        }
        return selectedGridExceptions;
    }

    function getSelectedOptionalBenefits() {
        let selectedOptionalBenefits: OptionalBenefitMapping[] = [];
        if (_allOptionalBenefits.length > 0 && isNotNullOrUndefined(_allOptionalBenefits)) {
            _allOptionalBenefits.forEach((item) => {
                if (!item.disableStatus) {
                    let selectedBenefit: OptionalBenefitMapping = item;
                    selectedBenefit.Unit =
                        (item.UnitType !== 'FreeField' && (item.Unit === '' || isNaN(Number(item.Unit))))
                        ? '1'
                        : item.Unit;
                        selectedBenefit.Amount = item.UnitType === 'FreeField' ? item.Amount : null;
                    selectedOptionalBenefits.push(selectedBenefit);
                }
            });
        }
        return selectedOptionalBenefits;
    }

    function getReloFormToSaveOrSubmit(isSubmit: boolean) {
        const attachmentInfoToSaveOrSubmit = getAttachmentsToSaveOrSubmit();
        const employeeInfoToSaveOrSubmit = getEmploymentInfoToSaveOrSubmit();
        const personalInfoToSaveOrSubmit = getPersonalInforToSaveOrSubmit();
        const hireInfoToSaveOrSubmit = getHireInfoToSaveOrSubmit();
    
        const selectedGridExceptions = getSelectedExceptions();
        const selectedOptionalBenefits = getSelectedOptionalBenefits();

        return {
            ...attachmentInfoToSaveOrSubmit,
            ...employeeInfoToSaveOrSubmit,
            ...personalInfoToSaveOrSubmit,
            ...hireInfoToSaveOrSubmit,
            OriginAddress: reloFormDataResponse[0].OriginAddress,
            OriginCity: reloFormDataResponse[0].OriginCity,
            OriginStateProvince: reloFormDataResponse[0].OriginStateProvince,
            PostalCode: reloFormDataResponse[0].PostalCode,
            OriginCountry: reloFormDataResponse[0].OriginCountry,
            Comments: _comments,
            RushComments: employmentInfo.RushComments,
            DestinationCompanyCode: employmentInfo.DestinationCompanyCode != null ? employmentInfo.DestinationCompanyCode : 0,
            DepartureCompanyCode: employmentInfo.DepartureCompanyCode != null ? employmentInfo.DepartureCompanyCode : 0,
            RequisitionID: employmentInfo.RequisitionID != null ? employmentInfo.RequisitionID : 0,
            ExceptionMapping: selectedGridExceptions.length > 0 ? selectedGridExceptions : [],
            FiscalYear: _fiscalYear,
            InternshipTypeID: reloFormDataResponse[0].InternshipTypeID,
            IsActive: false,
            IsEdit: _formStatus == 'New' ? false : true,
            IsWentInWebJE: false,
            LimitedBenefitExecutiveTaxPct: 0,
            LoggedInUser: userdata.Alias,
            LumpSumRelocation: _lumpSumRelocation ? _lumpSumRelocation : {},
            OptionalBenefitMapping:
                selectedOptionalBenefits.length > 0 ? selectedOptionalBenefits : [],
            PackageValue: '',
            PersonalDetails: {
                ...personalInfo.personalDetails,
                VisaPending:
                    personalInfo.personalDetails.VisaPending == '' || personalInfo.personalDetails.VisaPending == null
                        ? 'No'
                        : personalInfo.personalDetails.VisaPending,
            },
            PreHireTypeBenefits:
                _selectedPreHireTypeBenefits.length > 0 ? _selectedPreHireTypeBenefits : [],
            PreHireTypeBenefitsMapping: _preHireTypeBenefitsMapping,
            ReadyToReview: 0,
            RelocationCashAmount: _relocationCashAmount,
            RelocationPolicyID: _relocationPolicyID ? _relocationPolicyID!.toString() : '',
            RelocationpolicyTypeID: _relocationpolicyTypeID ? _relocationpolicyTypeID!.toString() : '',
            Status: !isSubmit ? 'Saved' : 'Submitted',
            TaxPctForLumpSum: _taxPctForLumpSum,
            TotalEstimate: reloFormDataResponse[0].TotalEstimate,
            TotalPackageValue: reloFormDataResponse[0].TotalPackageValue,
            UpdatedBySyncJob: reloFormDataResponse[0].UpdatedBySyncJob,
            DepartureStatus: reloFormDataResponse[0].DepartureStatus,
            PreHireTypeBenefitsList: [],
            CurrentDate: new Date().toString(),
            StartDate: '0001-01-01T00:00:00',
            CustFileID: reloFormDataResponse[0].CustFileID,
            SlackAmount: reloFormDataResponse[0].SlackAmount,
            coreBenefits: _coreBenefits,
            CoreAllowance: _coreAllowance,
            file: {},
            fileupload: {},
        };
    }
    
    async function OnSaveClick(isSubmit: boolean) {
        let formData: any = getReloFormToSaveOrSubmit(isSubmit);

        if (formData == undefined || formData == null) {
            alert('Please Enter a Valid Data Before Submitting The Form');
        } else {
            if (formData.HireTypeID != 6 && formData.HireTypeID != 7) {
                if (
                    formData.CandidateID == null ||
                    formData.CandidateID == undefined ||
                    formData.CandidateID == '' ||
                    parseInt(formData.CandidateID) == 0
                ) {
                    if (
                        formData.EmployeeID == null ||
                        formData.EmployeeID == undefined ||
                        formData.EmployeeID.toString() == '' ||
                        formData.EmployeeID == 0
                    ) {
                        alert(
                            'Please Enter a Valid Candidate ID or Employee ID or HireType Before Submitting The Form'
                        );
                    } else {
                        saveRelocationFormData(false, formData);
                    }
                } else {
                    saveRelocationFormData(false, formData);
                }
            } else {
                saveRelocationFormData(false, formData);
            }
        }
    }

    async function saveRelocationFormData(isSubmit: boolean, formData: INewAuthForm) {
        {
            let d = new Date(formData.EstimatedStartDate!);
            if (formData.EstimatedStartDate == null || formData.EstimatedStartDate == undefined) {
                d = new Date();
            }
            if (d.getMonth() > 5) {
                formData.FiscalYear = d.getFullYear() + 1;
                set_FiscalYear(formData.FiscalYear);
            } else {
                formData.FiscalYear = d.getFullYear();
                set_FiscalYear(formData.FiscalYear);
            }
            formData.LoggedInUser = userdata.Alias;
            if (!isSubmit) {
                formData.Status = 'Saved';
            } else {
                formData.Status = 'Submitted';
            }

            if (formData.HireTypeID != 4) formData.InternshipTypeID = 0;
            if (formData.HireTypeID == 7) {
                formData.PreHireTypeBenefits =
                    _selectedPreHireTypeBenefits.length > 0
                        ? _selectedPreHireTypeBenefits
                        : ({} as PreHireTypeBenefits[]);
            }

            formData.RelocationCashAmount = removeCommaOrDollar(formData.RelocationCashAmount);
            formData.TaxPctForLumpSum = _taxPctForLumpSum;
            setIsLoading(true);
            await startNewReloRequestService
                .SaveRelocationForm(formData)
                .then(
                    function (result) {
                        if (result.status == 201) {
                            formData.ReloID = result.data;
                            setHireInfo((preState) => ({ ...preState, reloID: result.data }));
                            set_FormStatus('SAVED');
                            setApiSuccess(true);
                            if (formData.Status.toUpperCase() == 'SAVED') {
                                setMessageText('Success! This record has been saved to your Relo Queue.');
                                setIsSaved(true);
                            }
                            if (formData.Status.toUpperCase() == 'SUBMITTED') {
                                setIsSaved(true);
                            }
                        } else {
                            setApiError(true);
                            setMessageText('Save NOT SUCCESS');
                            setIsSaved(false);
                        }
                    },
                    function (exception) {
                        console.log('The error message is ' + exception);
                    }
                )
                .catch(() => {
                    setApiError(true);
                    if (formData.Status.toUpperCase() == 'SAVED') {
                        setMessageText('Error! This record was not saved, please try again.');
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });
            return formData;
        }
    }

    async function uploadaddfile(file: File, i: number) {
        setIsLoading(true);
        await startNewReloRequestService
            .UploadRelocationSummary(file)
            .then(
                function (resp) {
                    let reloSummary = {
                            ReloID: hireInfo.reloID,
                            CreatedBy: userdata.Alias,
                            AttachReloSummaryID: 0,
                            AddtionalDescription: '',
                            Filename: file.name,
                            IsRemoved: false,
                            CreatedDate: new Date(),
                            IsAdditional: i
                        }
                    switch (i) {
                        case 0:
                            set_AttachmentGUID0(resp);
                            set_AdditionalReloSummary0(reloSummary);
                            break;
                        case 1:
                            set_AttachmentGUID1(resp);
                            set_AdditionalReloSummary1(reloSummary);
                            break;
                        case 2:
                            set_AttachmentGUID2(resp);
                            set_AdditionalReloSummary2(reloSummary);
                            break;
                        case 3:
                            set_AttachmentGUID3(resp);
                            set_AdditionalReloSummary3(reloSummary);
                            break;
                        case 4:
                            set_AttachmentGUID4(resp);
                            set_AdditionalReloSummary4(reloSummary);
                            break;
                    }
                    setApiSuccessRemovedFile0(false);
                    setApiSuccessRemovedFile1(false);
                    setApiSuccessRemovedFile2(false);
                    setApiSuccessRemovedFile3(false);
                    setApiSuccessRemovedFile4(false);
                    setApiSuccess(true);
                    setMessageText(
                        "Success! This attachment has been successfully uploaded. Please don't forget to click on SaveSubmit before leaving this page to complete the transaction."
                    );
                },
                function (err) { }
            )
            .catch(() => {
                setApiError(true);
                setMessageText('Error! This attachment was not uploaded, please try again.');
            })
            .finally(() => {
                setIsLoading(false);
            });
        return;
    }

    async function uploadfile(file: File) {
        setIsLoading(true);
        await startNewReloRequestService
            .UploadRelocationSummary(file)
            .then(
                function (resp) {
                    setApiSuccessRemovedFile0(false);
                    setApiSuccessRemovedFile1(false);
                    setApiSuccessRemovedFile2(false);
                    setApiSuccessRemovedFile3(false);
                    setApiSuccessRemovedFile4(false);
                    let reloSummary = {
                        ReloID: hireInfo.reloID,
                        CreatedBy: userdata.Alias,
                        AttachReloSummaryID: 0,
                        AddtionalDescription: '',
                        Filename: file.name,
                        IsRemoved: false,
                        CreatedDate: new Date(),
                        IsAdditional: 10
                    }
                    set_AttachmentGUID(resp);
                    set_RequiredReloSummary(reloSummary);
                    setApiSuccess(true);
                    setMessageText(
                        "Success! This attachment has been successfully uploaded. Please don't forget to click on Save\\Submit before leaving this page to complete the transaction."
                    );
                },
                function (err) {
                    alert(`File ${file.name} upload failed`);
                }
            )
            .catch(() => {
                setApiError(true);
                setMessageText('Error! This attachment was not uploaded, please try again.');
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    function RemoveSelectedReloFile() {
        set_AttachmentGUID('');
        set_RequiredReloSummary({} as AttachReloSummary);
    }

    function hidemessageBox(){
        setApiError(false);
        setApiSuccess(false);
    }

    function updateAdditionalDescriptionValue(value: string, index: number) {
        switch (index) {
            case 0:
                set_AdditionalReloSummary0({ ..._additionalReloSummary0, AddtionalDescription: value });
                break;
            case 1:
                set_AdditionalReloSummary1({ ..._additionalReloSummary1, AddtionalDescription: value });
                break;
            case 2:
                set_AdditionalReloSummary2({ ..._additionalReloSummary2, AddtionalDescription: value });
                break;
            case 3:
                set_AdditionalReloSummary3({ ..._additionalReloSummary3, AddtionalDescription: value });
                break;
            case 4:
                set_AdditionalReloSummary4({ ..._additionalReloSummary4, AddtionalDescription: value });
                break;
        }
    }

    function RemoveAddSelectedReloFile(index: number) {
        setApiSuccessRemovedFile0(false);
        setApiSuccessRemovedFile1(false);
        setApiSuccessRemovedFile2(false);
        setApiSuccessRemovedFile3(false);
        setApiSuccessRemovedFile4(false);
        switch (index) {
            case 0:
                set_AttachmentGUID0('');
                set_AdditionalReloSummary0({} as AttachReloSummary);
                setApiSuccessRemovedFile0(true);
                break;
            case 1:
                set_AttachmentGUID1('');
                set_AdditionalReloSummary1({} as AttachReloSummary);
                setApiSuccessRemovedFile1(true);
                break;
            case 2:
                set_AttachmentGUID2('');
                set_AdditionalReloSummary2({} as AttachReloSummary);
                setApiSuccessRemovedFile2(true);
                break;
            case 3:
                set_AttachmentGUID3('');
                set_AdditionalReloSummary3({} as AttachReloSummary);
                setApiSuccessRemovedFile3(true);
                break;
            case 4:
                set_AttachmentGUID4('');
                set_AdditionalReloSummary4({} as AttachReloSummary);
                setApiSuccessRemovedFile4(true);
                break;
        }
    }

    async function onInternshipTypeChange(InternshipTypeID: number) {
        reloFormDataResponse[0].InternshipTypeID = InternshipTypeID;
        if (
            reloFormDataResponse[0].InternshipTypeID != null &&
            reloFormDataResponse[0].InternshipTypeID != undefined
        ) {
            let selectedHireTypes = hireInfo.hireTypesOptions.filter((row) => {
                if (row.key === parseInt(props.hireTypeOption)) {
                    return row.text as string;
                }
            });
            let selectedHireType = selectedHireTypes[0].text;
            if (selectedHireType === 'Intern') {
                if (selectedHireType === 'Intern') {
                    setHiringManagerLabel('Program Manager (GMAT Request Approver)');
                    if (reloFormDataResponse[0].InternshipTypeID == 5) {
                        setShowHrStaffingManagerAliasTextBox(false);
                        employmentInfo.hrStaffingGroup = 'MSRRC';
                        employmentInfo.hRStaffingManagerAlias = '';
                        employmentInfo.hiringManagerAlias = '';
                        setHRStaffingNameLabel('Staffing Hire Group (For US)/ Staffing Manager (For Non US)');
                    } else if (reloFormDataResponse[0].InternshipTypeID == 6) {
                        setShowHrStaffingManagerAliasTextBox(false);
                        employmentInfo.hiringManagerAlias = '';
                        employmentInfo.hRStaffingManagerAlias = '';
                        employmentInfo.hrStaffingGroup = '';
                    } else {
                        setShowHrStaffingManagerAliasTextBox(false);
                        employmentInfo.hRStaffingManagerAlias = '';
                    }
                }
            }
        }
    }

    function removeCommaOrDollar(amount: any) {
        if (amount && amount.length != 0) {
            if (amount.replace) {
                amount = amount.replace(/$/, '');
                amount = amount.replace(/,/g, '');
            }
        } else amount = 0;
        return parseFloat(amount);
    }

    async function LoadRelocationType(selectedPolicyType: IDropdownOption) {
        let updatedReloTypes: any[] = [];
        let relocationPolicyTypeIsFull = '';
        let relocationpolicyTypeID: number | undefined = undefined;
        let relocationType;
        if (parseInt(props.hireTypeOption) != 4) reloFormDataResponse[0].InternshipTypeID = 0;
        let newRelocationPolicyTypeOptions:  IDropdownOption[] = [];
        const hireTypeResponse = await reviewFormService.getHireTypeService();
        let hiretypeoptions: IDropdownOption[] = [];
        hireTypeResponse.forEach((item) => {
            if (item.HireTypeID != 5 && item.HireTypeID != 6)
                hiretypeoptions.push({
                    key: item.HireTypeID,
                    text: item.HireTypeName,
                });
            if (item.HireTypeID == 6)
                hiretypeoptions.push({
                    key: item.HireTypeID,
                    text: 'Executive/ M&A',
                });
        });

        let selectedHireTypes = hiretypeoptions.filter((row) => {
            if (row.key === parseInt(props.hireTypeOption)) {
                return row.text as string;
            }
        });

        let selectedHireType = selectedHireTypes[0].text;

        if (parseInt(props.hireTypeOption) == 4 && reloFormDataResponse[0].InternshipTypeID == 5) {
            employmentInfo.hiringManagerAlias = '';
        }
        if (parseInt(props.hireTypeOption) == 3) {
            if (
                employmentInfo.hiringManagerAlias == null ||
                employmentInfo.hiringManagerAlias == '' ||
                employmentInfo.hiringManagerAlias == undefined
            ) {
                employmentInfo.hiringManagerAlias = '';
            }
        }

        if (isNotNullOrUndefined(selectedHireType) && selectedHireType == 'Intern') {
            if (employmentInfo.jobLevelID == '' || employmentInfo.jobLevelID == undefined)
                employmentInfo.jobLevelID = '';

            let selectedInternshipType = hireInfo.hireTypesOptions.filter((item) => {
                if (item.key == reloFormDataResponse[0].InternshipTypeID) {
                    return item;
                }
            })[0];

            if (selectedInternshipType !== null && selectedInternshipType !== undefined)
                hireInfo.showInternship = true;
            if (reloFormDataResponse[0].InternshipTypeID == 5 || reloFormDataResponse[0].InternshipTypeID == 6) {
                setShowHrStaffingManagerAliasTextBox(true);
                employmentInfo.hRStaffingManagerAlias = '';
                if (reloFormDataResponse[0].InternshipTypeID == 5) {
                    employmentInfo.hiringManagerAlias = '';
                    employmentInfo.hrStaffingGroup = 'MSRRC';
                }
            } else {
                setShowHrStaffingManagerAliasTextBox(false);
            }
        } else {
            hireInfo.showInternship = false;
        }

        if (selectedHireType == 'Intern' || selectedHireType == 'University FTE') {
            setShowHrStaffingManagerAliasTextBox(true);
            if (selectedHireType == 'University FTE') {
                setHiringManagerLabel('Hiring Manager Alias (Request Approver)');
                setShowHrStaffingManagerAliasTextBox(false);
                if (
                    employmentInfo.hiringManagerAlias == null ||
                    employmentInfo.hiringManagerAlias == '' ||
                    employmentInfo.hiringManagerAlias == undefined
                ) {
                    employmentInfo.hiringManagerAlias = '';
                }
            } else {
                setHiringManagerLabel('Program Manager (GMAT Request Approver)');
                if (reloFormDataResponse[0].InternshipTypeID == 5 || reloFormDataResponse[0].InternshipTypeID == 6) {
                    setShowHrStaffingManagerAliasTextBox(false);
                } else {
                    setShowHrStaffingManagerAliasTextBox(false);
                }
            }
        } else {;
            setShowHrStaffingManagerAliasTextBox(true);
            setHRStaffingNameLabel('E-form Submitters Manager Alias');
            setHiringManagerLabel('Hiring Manager Alias (Request Approver)');
        }

        if (
            hireInfo.hireTypeID !== null &&
            hireInfo.hireTypeID !== undefined &&
            hireInfo.hireTypeID !== 0 &&
            selectedPolicyType !== null &&
            selectedPolicyType !== ({} as IDropdownOption) &&
            selectedPolicyType !== undefined &&
            _relocationPolicyID !== 0
        ) {
            let resp = await reviewFormService.getrelocationType();
            updatedReloTypes = resp;
            let filteredReloTypesHirePol = updatedReloTypes.filter((type) => {
                return (
                    type.HireTypeID == parseInt(props.hireTypeOption) &&
                    type.RelocationPolicyID == selectedPolicyType?.key
                    && type.IsDeleted == false
                );
            });
            if (parseInt(props.hireTypeOption) === 4) {
                filteredReloTypesHirePol = filteredReloTypesHirePol.filter((item) => {
                    return item.RelocationTypeID != 37;
                });
            }
            let filteredReloTypes = [];
            for (let relType of filteredReloTypesHirePol) {
                if (
                    filteredReloTypes.filter((type) => type.RelocationTypeID == relType.RelocationTypeID).length === 0
                ) {
                    filteredReloTypes.push(relType);
                }
            }

            if (filteredReloTypes !== undefined && filteredReloTypes.length > 0) {
                if (
                    filteredReloTypes.filter((item) => {
                        return item.HireTypeID === 3 && item.RelocationPolicyID === 2;
                    }).length >= 1
                ) {
                    filteredReloTypes = filteredReloTypes.filter((item) => {
                        return item.RelocationTypeID != 12 && item.RelocationTypeID != 13;
                    });
                }
                updatedReloTypes = filteredReloTypes;
                if (isNotNullOrUndefined(relocationpolicyTypeID)) {
                    changeReloType(selectedPolicyType);
                    let currentRelocationType = updatedReloTypes.filter((item) => {
                        return item.RelocationTypeID == relocationpolicyTypeID;
                    });
                    relocationPolicyTypeIsFull = '';
                    if (isNotNullOrUndefined(currentRelocationType) && currentRelocationType.length > 0) {
                        relocationType = currentRelocationType[0].RelocationTypeName;

                        if (currentRelocationType[0].ReloPolicyType != null) {
                            relocationPolicyTypeIsFull = currentRelocationType[0].ReloPolicyType;
                        }
                    } else {
                        relocationPolicyTypeIsFull = 'Full';
                    }

                    await getAllOptionalBenefits(
                        relocationPolicyTypeIsFull,
                        relocationpolicyTypeID ? relocationpolicyTypeID : 0
                    );
                }
            } else {
                updatedReloTypes = [];
            }

            newRelocationPolicyTypeOptions = updatedReloTypes.map(item => ({ key: item.RelocationTypeID, text: item.RelocationTypeName }));

            let selectedReloPolicy = updatedReloTypes.filter((item) => {
                item.RelocationPolicyID == _relocationPolicyID;
            })[0];

        } else {
            setReloTypes([]);
        }
        set_RelocationPolicyID(selectedPolicyType!.key as number);
        set_RelocationpolicyTypeID(0);
        setRelocationType(relocationType);
        setReloTypes(updatedReloTypes);
        setRelocationPolicyTypeOptions(newRelocationPolicyTypeOptions);
        set_LumpSumRelocation({} as LumpSumRelocation);
        set_policyReloTypeChanged(true);
    }
};